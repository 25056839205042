<template>
    <b-alert show variant="warning" v-if="conditions">
        Dipende dalla domanda
        <br />
        <b>{{ conditions.description }}</b>
        <br />
        <hr />
        Valori di attivazione
        <table style="width: 100%">
            <tr v-for="(group, index) of conditions.groups" :key="index">
                <td v-if="conditions.groups.length > 1">
                    <b>{{ group }}</b>
                </td>
                <td>{{ conditions.value[index].length ? conditions.value[index].join(", ") : "Nessuno" }}</td>
            </tr>
        </table>
    </b-alert>
</template>
<script>
import _ from "lodash";

export default {
	name: "question-condition-info",
	props: ["question"],
	data() {
		return {
			conditions: null,
		};
	},
	async created() {
		try {
			this.conditions = { ...this.question.conditions };
			if (Array.isArray(this.conditions.value)) {
				this.conditions.value = _.flattenDeep(this.conditions.value);
			}

			if (this.conditions.groups.length == 1) {
				this.conditions.value = [this.conditions.value]; // re-map per il rendering
			}
		} catch (e) {
			this.conditions = null;
		}
	},
};
</script>
