<template>
    <div style="margin: 2em">
        <i>Report delle misure e degli indicatori del progetto Pascal</i>
        <h1>Le misure del Comune di {{ office_name }}</h1>
        <b-overlay :show="loading" rounded="sm">
            <div v-for="pillar in pillars" :key="pillar.id">
                <h2>{{ pillar.id }}-{{ pillar.name }}</h2>

                <div v-for="m in filterMeasures(pillar.id).values()" :key="m.id">
                    <b-media tag="div" class="mt-3">
                        <template v-slot:aside>
                            <b-img v-if="m.img" :src="`${serverPath}/img/${m.img}`" width="200" height="130"></b-img>
                        </template>
                        <h3 class="mt-0 mb-1">
                            {{ m.name }}
                        </h3>
                        <p class="">
                            {{ m.description }}
                        </p>
                    </b-media>

                    <h5>Monitoraggio</h5>
                    <b-table small :items="serie(m.id)" v-if="serie(m.id).length" :fields="getMonitoringColumns"> </b-table>

                    <h5>Impatto</h5>
                    <div v-for="row_index in serie(m.id).length" :key="row_index">
                        <impact :mid="m.id" :measure="m" :monitoring="monitorings.get(m.id)" :row-index="row_index - 1"></impact>
                    </div>
                </div>
                <hr />
            </div>
        </b-overlay>
    </div>
</template>

<script>
import Impact from "@/components/Pascal/Impact";
import measures from "@/data/pascal-measures";
import pillars from "@/data/pascal-pillars";
import Filters from "@/mixing/filters";

import UserService from "@/services/user.service";
import Util from "@/mixing/util";

export default {
	name: "PascalReport",
	components: {
		Impact,
	},
	mixins: [Filters, Util],
	data() {
		return {
			office_id: this.$route.params.office_id,
			office_name: null,
			publicPath: process.env.BASE_URL,
			serverPath: process.env.VUE_APP_ROOT,
			mainProps: { blank: true, blankColor: "#777", width: 75, height: 75, class: "m1" },
			showPillar: null,
			//Trick for making charts update on div show
			//https://michaelnthiessen.com/force-re-render/
			renderComponent: false,

			pillars: pillars,
			all_measures: measures,
			measures: new Map(),
			measure_id: 0,
			monitorings: new Map(),
			monitorings_tmp: {
				name: null,
				dt: null,
			},
		};
	},
	methods: {
		serie(measure_id) {
			let s = this.monitorings.get(measure_id).series;
			return s;
		},
		filterMeasures(pillar_id) {
			let res = new Map();
			this.measures.forEach((m) => {
				if (m.pillar_id == pillar_id) {
					res.set(m.id, m);
				}
			});
			return res;
		},
		countMeasures(pillar_id) {
			return this.filterMeasures(pillar_id).size;
		},
		forceRerender() {
			// Remove my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Add the component back in
				this.renderComponent = true;
			});
		},

		async fetchMonitorings(measure_id) {
			this.measure_id = measure_id;
			//console.log();
			let result = null;

			try {
				result = await UserService.getMonitorings(this.office_id, this.measure_id);
			} catch (error) {
				this.showError(error);
			} finally {
				//this.loading = false;
			}

			let series = result.data.monitorings;
			let outs = [];
			series.map((element) => {
				let jv = element.jvalues;
				if (jv != null) {
					Object.entries(jv).forEach(([key, value]) => {
						element[key] = value;
					});
				}
				delete element.jvalues;
				delete element.created;
				delete element.modified;
				delete element.office_id;
				delete element.measure_id;

				//TODO: Generalizzare con la vera traccia dell'output
				outs.push({ riduzione_km_gg_auto: 0, C: 0, CO: 0, CO2: 0, NOx: 0, PM10: 0 });
			});
			return series;
		},
		async getMunicipality() {
			try {
				let res = await UserService.getOffice(this.office_id);
				this.office_name = res.data.office.name;
				this.active_measures = res.data.office.pascal_measures;
				let self = this;
				this.all_measures.forEach((x) => {
					if (self.active_measures.includes(x.id)) {
						self.measures.set(x.id, x);
					}
				});
			} catch (error) {
				this.showError(error);
			}
		},
		getMonitoringColumns(measure_id) {
			let res = [
				{ key: "name", label: "nome punto di monitoraggio" },
				{ key: "dt", label: "Data" },
			];
			let m = this.measures.get(measure_id);
			if (m !== undefined) {
				res = res.concat(m.labels);
			}

			return res;
		},
		async saveWord() {
			const result = await window.chooseFileSystemEntries({ type: "save-file" });
			console.log(result);
		},
	},
	computed: {
		currentMeasureName() {
			let m = this.measures.get(this.measure_id);
			if (m == undefined) {
				return "-";
			}
			return m.name;
		},
	},
	async mounted() {
		this.loading = true;
		let num_measures = this.all_measures.length;

		this.monitorings = new Map();
		for (let i = 1; i <= num_measures; i++) {
			this.monitorings.set(i, {
				series: [],
				out: [{ riduzione_km_gg_auto: 0, C: 0, CO: 0, CO2: 0, NOx: 0, PM10: 0 }],
			});
		}

		let self = this;
		await this.getMunicipality();
		for (const m of measures) {
			self.monitorings.get(m.id).series = await self.fetchMonitorings(m.id);
		}
		this.loading = false;
	},
};
</script>
