const validitaTrenord = [
	//bisogna sostituire il testo con variabili che si aggiornano con il passaggio del tempo
	{
		validita_abbonamento: 10,
		ordina_entro_il_gg: 25,
		ordina_entro_il_mm: 8,
	},
	{
		validita_abbonamento: 11,
		ordina_entro_il_gg: 22,
		ordina_entro_il_mm: 9,
	},
	{
		validita_abbonamento: 12,
		ordina_entro_il_gg: 16,
		ordina_entro_il_mm: 10,
	},
	{
		validita_abbonamento: 1,
		ordina_entro_il_gg: 5,
		ordina_entro_il_mm: 11,
	},
	{
		validita_abbonamento: 2,
		ordina_entro_il_gg: 5,
		ordina_entro_il_mm: 12,
	},
	{
		validita_abbonamento: 3,
		ordina_entro_il_gg: 15,
		ordina_entro_il_mm: 1,
	},
	{
		validita_abbonamento: 4,
		ordina_entro_il_gg: 15,
		ordina_entro_il_mm: 2,
	},
	{
		validita_abbonamento: 5,
		ordina_entro_il_gg: 15,
		ordina_entro_il_mm: 3,
	},
	{
		validita_abbonamento: 6,
		ordina_entro_il_gg: 15,
		ordina_entro_il_mm: 4,
	},
	{
		validita_abbonamento: 7,
		ordina_entro_il_gg: 16,
		ordina_entro_il_mm: 5,
	},
	{
		validita_abbonamento: 8,
		ordina_entro_il_gg: 22,
		ordina_entro_il_mm: 6,
	},
	{
		validita_abbonamento: 9,
		ordina_entro_il_gg: 22,
		ordina_entro_il_mm: 6,
	},
];

export default validitaTrenord;
