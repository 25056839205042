import UserService from "@/services/user.service";
import authService from "@/services/auth.service";

export default {
	data() {
		return {
			logo: process.env.VUE_APP_ICON,
			help_mail: process.env.VUE_APP_HELP_MAIL,
			title: process.env.VUE_APP_TITLE_LONG,
			login_url: process.env.VUE_APP_LOGIN_URL,
			publicPath: process.env.BASE_URL,
			mapConfig: JSON.parse(process.env.VUE_APP_MAP_CONFIG),
			apiPath: process.env.VUE_APP_ROOT,
			siteDir: process.env.VUE_APP_AMB,
			userRoles: JSON.parse(process.env.VUE_APP_USER_ROLES).sort(),
			loading: false,
			canShowTranslation: (process.env.VUE_APP_TRANSLATE == 1),
		};
	},
	methods: {
		isAuthorized(authorize) {
			const loggedIn = this.getUser;
			if (!loggedIn) {
				return false;
			}

			if (authorize !== undefined) {
				//Importante fare il trim, altrimenti si porta dietro lo spazio
				let au = authorize.split(",").map((s) => s.trim());
				let ret = au.includes(loggedIn.role);
				//console.log(au, loggedIn.role, ret);
				return  ret;
			}
			//Se non hai specificato authorize li faccio vedere sempre
			return true;
		},
		showError(e) {
			console.log(e.response);
			this.$bvToast.toast(e.response.data.message, {
				title: "Errore",
				autoHideDelay: 2000,
				appendToast: true,
				variant: "danger",
			});
		},
		setUser(user) {
			window.$cookies.set("user", JSON.stringify(user), "48h");
		},
		delUser() {
			window.$cookies.remove("user");
		},
		mySurvey() {
			let url = sessionStorage.getItem("mySurvey");
			if (url !== null) {
				return url;
			}
			url = "#";
			UserService.getMySurvey().then(
				(res) => {
					let survey_id = res.data.survey_id;
					let particpant_id = res.data.participant_id;

					if (survey_id !== null && particpant_id !== null) {
						url = `/questionari/fill/${survey_id}/${particpant_id}`;
					}
					sessionStorage.setItem("mySurvey", url);
				},
				(e) => {
					this.showError(e);
					sessionStorage.removeItem("mySurvey");
				}
			);
			return url;
		},
		download($url) {
			this.btnloading = true;
			UserService.download($url).then(() => {
				this.btnloading = false;
			});
		},
		logout() {
			authService.logout();
			this.$router.push({ path: "/" + this.login_url });
		},
	},
	computed: {
		canShowMobilityLabel() {
			return process.env.VUE_APP_MOBILITY_LABEL == 1;
		},
		//For some instances (5t) we need not to allow nominal survey but anonymous only
		canShowAnonymousSurveyOnly() {
			return process.env.VUE_APP_ANON_ONLY == 1;
		},
		canShowUserGuide() {
			return process.env.VUE_APP_USER_GUIDE == 1;
		},
		canShowImpactsXLS() {
			if (process.env.VUE_APP_SHOW_IMPACTS_XLS == undefined ){
				return false;
			}
			return process.env.VUE_APP_SHOW_IMPACTS_XLS == 1;
		},
		getUser() {
			return window.$cookies.get("user");
		},
		getUserId() {
			const loggedIn = this.getUser;
			return loggedIn ? loggedIn.id : null;
		},
		getUserCompany() {
			const loggedIn = this.getUser;
			return loggedIn.company_id;
		},
		loggedUserIsMomaArea() {
			const loggedIn = this.getUser;
			return loggedIn ? loggedIn.role == "moma" && !loggedIn.company_id : false;
		},
		loggedUserIsMomaManagerAziendale() {
			const loggedIn = this.getUser;
			return loggedIn ? loggedIn.role == "moma" && !isNaN(loggedIn.company_id) : false;
		},
		loggedUserIsAdmin() {
			const loggedIn = this.getUser;
			return loggedIn ? loggedIn.role == "admin" : false;
		},
		canEditOrari() {
			const loggedIn = this.getUser;
			return loggedIn ? loggedIn.role == "admin" || (loggedIn.role == "superiori" && loggedIn.company_id !== null) : false;
		},
		getMapOptions() {
			return {
				zoomSnap: this.mapConfig.zoomSnap,
				tileSize: this.mapConfig.tileSize,
				zoomOffset: this.mapConfig.zoomOffset,
				maxZoom: 18,
			};
		},
		getLayerOptions() {
			return  {
				tileSize: this.mapConfig.tileSize,
				zoomOffset: this.mapConfig.zoomOffset,
			};
		},
		getTilesUrl() {
			return this.mapConfig.url;
		},
		getMapAttribution(){
			return this.mapConfig.attribution;
		},
	},
};
