//Web Service per le tariffe  https://admin.trenord.it/store-management-api/CatalogueProducts?origin=S01797&destination=S01700
//Pagina Web Tariffe: https://www.trenord.it/store/#/it/subscriptions/step1?mir_f=S01797&departure=BRESCIA%20BORGO%20SAN%20GIOVANNI&mir_t=S01700&destination=MILANO%20CENTRALE

const tariffe_trenord = {
	"STIMB - Urbano Città di Milano": {
		tariff_id: "1500",
		description: "Abbonamento Annuale STIMB - Urbano Città di Milano",
		price: 330,
	},
	"STIMB - Mi1-Mi3": {
		tariff_id: "3628",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi3",
		price: 460,
	},
	"STIMB - Mi1-Mi4": {
		tariff_id: "8800",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi4",
		price: 552,
	},
	"STIMB - Mi1-Mi5": {
		tariff_id: "8801",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi5",
		price: 644,
	},
	"STIMB - Mi1-Mi6": {
		tariff_id: "8802",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi6",
		price: 682,
	},
	"STIMB - Mi1-Mi7": {
		tariff_id: "8803",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi7",
		price: 712,
	},
	"STIMB - Mi1-Mi8": {
		tariff_id: "8804",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi8",
		price: 738,
	},
	"STIMB - Mi1-Mi9": {
		tariff_id: "8805",
		description: "Abbonamento Annuale STIMB - Zone Mi1-Mi9",
		price: 761,
	},
	"STIMB - Mi3-Mi4": {
		tariff_id: "8806",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi4",
		price: 368,
	},
	"STIMB - Mi3-Mi5": {
		tariff_id: "8807",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi5",
		price: 460,
	},
	"STIMB - Mi3-Mi6": {
		tariff_id: "8808",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi6",
		price: 552,
	},
	"STIMB - Mi3-Mi7": {
		tariff_id: "8809",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi7",
		price: 644,
	},
	"STIMB - Mi3-Mi8": {
		tariff_id: "8810",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi8",
		price: 682,
	},
	"STIMB - Mi3-Mi9": {
		tariff_id: "8811",
		description: "Abbonamento Annuale STIMB - Zone Mi3-Mi9",
		price: 712,
	},
	"STIMB - Mi4-Mi5": {
		tariff_id: "8812",
		description: "Abbonamento Annuale STIMB - Zone Mi4-Mi5",
		price: 368,
	},
	"STIMB - Mi4-Mi6": {
		tariff_id: "8813",
		description: "Abbonamento Annuale STIMB - Zone Mi4-Mi6",
		price: 460,
	},
	"STIMB - Mi4-Mi7": {
		tariff_id: "8814",
		description: "Abbonamento Annuale STIMB - Zone Mi4-Mi7",
		price: 552,
	},
	"STIMB - Mi4-Mi8": {
		tariff_id: "8815",
		description: "Abbonamento Annuale STIMB - Zone Mi4-Mi8",
		price: 644,
	},
	"STIMB - Mi4-Mi9": {
		tariff_id: "8816",
		description: "Abbonamento Annuale STIMB - Zone Mi4-Mi9",
		price: 682,
	},
	"STIMB - Mi5-Mi6": {
		tariff_id: "8817",
		description: "Abbonamento Annuale STIMB - Zone Mi5-Mi6",
		price: 368,
	},
	"STIMB - Mi5-Mi7": {
		tariff_id: "8818",
		description: "Abbonamento Annuale STIMB - Zone Mi5-Mi7",
		price: 460,
	},
	"STIMB - Mi5-Mi8": {
		tariff_id: "8819",
		description: "Abbonamento Annuale STIMB - Zone Mi5-Mi8",
		price: 552,
	},
	"STIMB - Mi5-Mi9": {
		tariff_id: "8820",
		description: "Abbonamento Annuale STIMB - Zone Mi5-Mi9",
		price: 644,
	},
	"STIMB - Mi6-Mi7": {
		tariff_id: "8821",
		description: "Abbonamento Annuale STIMB - Zone Mi6-Mi7",
		price: 368,
	},
	"STIMB - Mi6-Mi8": {
		tariff_id: "8822",
		description: "Abbonamento Annuale STIMB - Zone Mi6-Mi8",
		price: 460,
	},
	"STIMB - Mi6-Mi9": {
		tariff_id: "8823",
		description: "Abbonamento Annuale STIMB - Zone Mi6-Mi9",
		price: 552,
	},
	"STIMB - Mi7-Mi8": {
		tariff_id: "8824",
		description: "Abbonamento Annuale STIMB - Zone Mi7-Mi8",
		price: 368,
	},
	"STIMB - Mi7-Mi9": {
		tariff_id: "8825",
		description: "Abbonamento Annuale STIMB - Zone Mi7-Mi9",
		price: 460,
	},
	"STIMB - Mi8-Mi9": {
		tariff_id: "8826",
		description: "Abbonamento Annuale STIMB - Zone Mi8-Mi9",
		price: 368,
	},

	"Fascia 1-5 Km": {
		tariff_id: "13001", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 1-5 Km",
		price: 264,
	},
	"Fascia 6-10 Km": {
		tariff_id: "13002", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 6-10 Km",
		price: 337,
	},
	"Fascia 11-15 Km": {
		tariff_id: "13003", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 11-15 Km",
		price: 409,
	},
	"Fascia 16-20 Km": {
		tariff_id: "13004", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 16-20 Km",
		price: 470,
	},
	"Fascia 21-25 Km": {
		tariff_id: "13005", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 21-25 Km",
		price: 538,
	},
	"Fascia 26-30 Km": {
		tariff_id: "13006", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 26-30 Km",
		price: 597,
	},
	"Fascia 31-35 Km": {
		tariff_id: "13007", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 31-35 Km",
		price: 658,
	},
	"Fascia 36-40 Km": {
		tariff_id: "13008", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 36-40 Km",
		price: 711,
	},
	"Fascia 41-50 Km": {
		tariff_id: "13009", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 41-50 Km",
		price: 807,
	},
	"Fascia 51-60 Km": {
		tariff_id: "13011", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 51-60 Km",
		price: 892,
	},
	"Fascia 61-70 Km": {
		tariff_id: "13012", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 61-70 Km",
		price: 945,
	},
	"Fascia 71-80 Km": {
		tariff_id: "13013", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 71-80 Km",
		price: 988,
	},
	"Fascia 81-90 Km": {
		tariff_id: "13098",
		description: "Abbonamento Annuale Trenord - Fascia 81-90 Km",
		price: 1016,
	},
	"Fascia 91-100 Km": {
		tariff_id: "13014", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 91-100 Km",
		price: 1042,
	},
	"Fascia 101-120 Km": {
		tariff_id: "13015", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 101-120 Km",
		price: 1088,
	},
	"Fascia 121-140 Km": {
		tariff_id: "13016", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 121-140 Km",
		price: 1136,
	},
	"Fascia 141-160 Km": {
		tariff_id: "13017", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 141-160 Km",
		price: 1192,
	},
	"Fascia 161-180 Km": {
		tariff_id: "13018", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Abbonamento Annuale Trenord - Fascia 161-180 Km",
		price: 1237,
	},
	"Io Viaggio Ovunque in Lombardia": {
		tariff_id: "12203",
		description: "Io Viaggio Lombardia",
		price: 1059,
	},
	
};

export default tariffe_trenord;
