<template>
    <div class="row">
        <div class="col-12">
            <card title="Gestione Posteggio" subTitle="Dipendenti e Tempi di Percorrenza">
                <div slot="raw-content" class="table-responsive">
                    <b-table :items="table1"></b-table>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
const tableData = [
	{
		id: 1,
		name: "Giovanni Rana",
		tempo: 120,
		distanza: 20,
		citta: "Torino",
	},
	{
		id: 2,
		name: "Francesco Ferrante",
		tempo: 10,
		distanza: 2,
		citta: "Torino",
	},
	{
		id: 3,
		name: "Michela Santori",
		tempo: 25,
		distanza: 25,
		citta: "Chieri",
	},
];

export default {
	data() {
		return {
			table1: tableData,
		};
	},
};
</script>
<style></style>
