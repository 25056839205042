<template>
    <div>
        <!--Stats cards-->
        <div class="row">
            <div class="col-md-4 col-xl-4" v-for="stats in statsCards" :key="stats.title">
                <stats-card>
                    <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
                        <i :class="stats.icon"></i>
                    </div>
                    <div class="numbers" slot="content">
                        <p>{{ stats.title }}</p>
                        {{ stats.value }}
                    </div>
                    <div class="stats" slot="footer"><i :class="stats.footerIcon"></i> {{ stats.footerText }}</div>
                </stats-card>
            </div>
        </div>

        <!--Charts-->
        <div class="row">
            <div class="col-md-4 col-12">
                <b-card title="Split Modale" style="max-width: 200rem">
                    <GChart type="PieChart" :options="pieOptions" :data="pieModalSplit" />
                </b-card>
            </div>
            <div class="col-md-4 col-12">
                <b-card title="Alimentazione" style="max-width: 200rem">
                    <GChart type="BarChart" :options="histoOptions" :data="histCarburante" />
                </b-card>
            </div>
            <div class="col-md-4 col-12">
                <b-card title="Emissioni" style="max-width: 200rem">
                    <GChart type="Table" :options="histoOptions" :data="histEmissioni" />
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import { StatsCard } from "@/components/index";
import UserService from "@/services/user.service";
import { GChart } from "vue-google-charts";
import Util from "@/mixing/util.js";

const statsCards = [
	{
		type: "success",
		icon: "ti-direction",
		title: "Aziende e Scuole",
		value: "",
		footerText: "Aggiornato ora",
		footerIcon: "ti-reload",
	},
	{
		type: "warning",
		icon: "ti-view-list-alt",
		title: "Risp. Questionari",
		value: "",
		footerText: "Aggiornato ora",
		footerIcon: "ti-reload",
	},
	{
		type: "danger",
		icon: "ti-user",
		title: "Utenti su mappa",
		value: "",
		footerText: "Aggiornato ora",
		footerIcon: "ti-reload",
	},
];
export default {
	components: {
		StatsCard,
		// ChartCard,
		GChart,
	},
	mixins: [Util],
	data() {
		return {
			statsCards: statsCards,
			companies: null,
			company_id: null,
			// All charts Variables
			pieModalSplit: null,
			histCarburante: null,
			histEmissioni: null,

			labelsModalSplit: null,
			seriesModalSplit: null,
			indicator_typeModalSplit: null,

			labelsCarburante: null,
			seriesCarburante: null,
			indicator_typeCarburante: null,

			labelsEmissioni: null,
			seriesEmissioni: null,
			indicator_typeEmissioni: null,
			// Pie chart 
			pieOptions: {
				title: null,
				titleTextStyle: {
					bold: false,
				},
				height: 530,
				legend: { position: "right" },
				pieHole: 0.5,
			},
			// hist Chart
			histoOptions: {
				title: null,
				titleTextStyle: {
					bold: false,
				},
				height: 530,
				vAxis: {
					title: "",
				},
				legend: { position: "bottom" },
			},

			testData: null,
		};
	},
	methods: {},
	async created() {
		let self = this;
		//Se c'è l'utente ha un azienda, filtra su quel valore
		this.company_id = this.getUserCompany;

		const response = await Promise.all([
			UserService.getAnswerIndicator("ripartizione_modale", this.company_id), //0
			UserService.getAnswerIndicator("carburante", this.company_id), //1
			UserService.getAnswerIndicator("emissioni", this.company_id), //2
			UserService.getCompanyList()// 3
		]);
		self.companies = response[3].data.companies;

		self.labelsModalSplit = response[0].data.labels;
		self.seriesModalSplit = response[0].data.series;
		self.indicator_typeModalSplit = response[0].data.Type;

		self.labelsCarburante = response[1].data.labels;
		self.seriesCarburante = response[1].data.series;
		self.indicator_typeCarburante = response[1].data.Type;

		self.labelsEmissioni = response[2].data.labels;
		self.seriesEmissioni = response[2].data.series;
		self.indicator_typeEmissioni = response[2].data.Type;

		// Modal Split Pie Chart
		this.pieModalSplit = [["descrizione", "qta"]];
		for (let i = 0; i < self.labelsModalSplit.length; i++) {
			this.pieModalSplit.push([self.labelsModalSplit[i], parseFloat(self.seriesModalSplit[0][i])]);
		}

		// Carburante histogram
		self.histCarburante = [["descrizione"], [""]];
		self.labelsCarburante.forEach((element) => {
			self.histCarburante[0].push(element);
		});
		self.seriesCarburante[0].forEach((element) => {
			self.histCarburante[1].push(parseFloat(element));
		});

		// Emissioni histogram
		self.histEmissioni = [["fattore"], ["Kg/anno"]];
		self.labelsEmissioni.forEach((element) => {
			self.histEmissioni[0].push(element);
		});
		self.seriesEmissioni[0].forEach((element) => {
			self.histEmissioni[1].push(parseFloat(element));
		});

    	self.histEmissioni =  self.histEmissioni[0].map((col, i) => self.histEmissioni.map(row => row[i]));

		// self.testData = await UserService.getModalSplit();

		let r = "";
		r = await UserService.countCompanies();
		this.statsCards[0].value = r.data.count;

		r = await UserService.countResponders();
		this.statsCards[1].value = r.data.count;

		r = await UserService.countEmployees();
		this.statsCards[2].value = r.data.count;
	},
};
</script>

<style></style>
