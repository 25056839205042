<template>
  <div>
    <b-row>
      <b-col>
        <h1>
          <b-badge v-if="isTemplate()">Template</b-badge> {{ survey.name }}
          <span
            v-if="!isTemplate()"
            class="float-right"
            style="font-size: 18px">
            <router-link :to="`/questionari/fill/${survey.id}/test`" target="_blank"><i class="fa fa-list-alt" aria-hidden="true"></i>
              Mostra Anteprima Questionario
            </router-link>
          </span>
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-card v-if="isTemplate()">
          <b-form-group label="Titolo del Modello">
            <b-form-input v-model="survey.name"></b-form-input>
          </b-form-group>
          <b-form-group label="Descrizione del Modello">
            <b-form-input v-model="survey.description"></b-form-input>
          </b-form-group>
          <b-button
            @click="updateSurvey(false)"
            type="button"
            variant="primary"
            class="float-right">Salva</b-button>
        </b-card>
      </b-col>

      <b-col lg="12">
        <b-card-group deck v-if="!isTemplate()">
          <b-card header="Informazioni sul questionario">
            <form action="">              
              <b-form-group label="Titolo del Questionario">
                <b-form-input v-model="survey.name"></b-form-input>
              </b-form-group>
              
              <b-form-group>
                
                <b-form-row>
                  <b-col v-if="!hasLogofile">
                    <b-img v-if="survey.logo" :src="`${this.serverPath}${this.amb}${this.survey.logo}?${this.imgKey}`" alt="Logo Questionario" width="150"></b-img>
                  </b-col>
                  <b-col v-if="hasLogofile">
                    <b-img :src="logofileSrc" width="150"></b-img>
                  </b-col>
                  <b-col>
                    <div slot="label">
                    Logo 
                    </div>
                    <b-form-file v-model="logofile"
                    :state="Boolean(logofile)"
                    placeholder="Carica il tuo logo..."
                    drop-placeholder="Drop file here..." 
                    accept=".jpg, .png, .gif"></b-form-file>
                    <div>
                        File selezionato:
                        {{ logofile ? logofile.name : "" }}
                    </div>
                  </b-col>
                </b-form-row>
              </b-form-group>

              <b-form-group label="Descrizione">
                <b-form-input v-model="survey.description"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-row>
                  <b-col>
                    <label for="survey-datepicker">Data Riferimento</label>
                  </b-col>
                  <b-col>
                    <b-form-datepicker
                      id="survey-datepicker"
                      v-model="survey.date"
                      size="sm"
                      right></b-form-datepicker>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group label="Tag Versione">
                <b-form-input v-model="survey.version_tag"></b-form-input>
                <span class="text-muted small">Scrivi una parola per associare questo questionario ad un
                  evento specifico (es: anno, invio).</span>
              </b-form-group>

              <b-form-group label="Messaggio di Benvenuto (Web)">
                <div v-if="!loggedUserIsAdmin" class="text-info">
                  <b-icon-info-circle-fill></b-icon-info-circle-fill> Per
                  questioni di privacy policy il tuo account non può modificare
                  il messaggio di benvenuto
                </div>
                <ckeditor
                  :editor="editor"
                  v-model="survey.welcome"
                  :config="editorConfig"
                  :disabled="!loggedUserIsAdmin"></ckeditor>
                <span class="text-muted small">Questo messaggio viene visualizzato nel browser all'apertura
                  del questionario.</span>
              </b-form-group>
              
              <input type="hidden" name="id" :value="survey.id" />
              <b-button
                @click="updateSurvey(false)"
                type="button"
                variant="primary"
                class="float-right">Salva</b-button>
            </form>
          </b-card>

          <b-card header="Modalità di invio">
            Da questo pannello puoi gestire il questionario della tua azienda.
            <hr />

            <b-form action="">
              <b-form-row>
                <b-col>
                  <label for="survey-opening">Data Apertura Questionario</label>
                </b-col>
                <b-col>
                  <b-form-datepicker
                    id="survey-opening"
                    name="survey-opening"
                    v-model="survey.opening_date"
                    size="sm"
                    right></b-form-datepicker>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <label for="survey-closing">Data Chiusura Questionario</label>
                </b-col>
                <b-col>
                  <b-form-datepicker
                    id="survey-closing"
                    name="survey-closing"
                    v-model="survey.closing_date"
                    size="sm"
                    right></b-form-datepicker>
                </b-col>
              </b-form-row>

              <span class="text-muted">Consiglio: due settimane sono solitamente un buon tempo</span>

              <h4>Tipo di invio</h4>
              <b-form-group>
                <b-form-radio
                  v-model="survey.sending_mode"
                  name="sending_mode"
                  value="n"
                  v-if="!canShowAnonymousSurveyOnly">
                  Invio tramite piattaforma (nominale)
                </b-form-radio>

                <b-form-radio
                  v-model="survey.sending_mode"
                  name="sending_mode"
                  value="z"
                  v-if="!canShowAnonymousSurveyOnly">
                  Invio tramite piattaforma (anonimizzato)
                </b-form-radio>

                <b-form-radio
                  v-model="survey.sending_mode"
                  name="sending_mode"
                  value="a">
                  Invio tramite mail e link anonimo
                  <div v-if="canShowAnonymousSurveyOnly" class="text-info">
                    <b-icon-info-circle-fill></b-icon-info-circle-fill> Per
                    questioni di privacy policy il tuo account può solo inviare
                    i questionari in modalità anonima.
                  </div>
                </b-form-radio>
              </b-form-group>

              <div v-if="isAnonymous || canShowAnonymousSurveyOnly">
                <label for="basic-url">Link Anonimo</label>
                <div class="text-info">
                  <b-icon-info-circle-fill></b-icon-info-circle-fill> Puoi
                  copiare questo link in una e-mail che può essere inviata dalla
                  casella aziendale. Le risposte al questionario saranno anonime
                  e non potrai sapere chi ha risposto o meno.
                </div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    :value="surveyAnonymousLink"
                    id="anon-link"
                    style="width: 70%"/>
                  <div class="input-group-append">
                    <span
                      class="btn btn-info text-white copy-btn ml-auto"
                      @click.stop.prevent="copySurveyAnonymousLink">
                      Copia
                    </span>
                  </div>
                </div>
              </div>

              <b-form-group v-if="!isAnonymous && !canShowAnonymousSurveyOnly">
                <h4>Partecipanti</h4>
                <b-form-row>
                  <b-col>
                    <p class="text-danger" v-if="survey.participants_num == 0">
                      <i class="fa fa-warning" aria-hidden="true"></i>
                      Nessun partecipante disponibile per il questionario.
                      Importali usando il pulsante "Partecipanti"
                    </p>
                    <p v-else>
                      <i class="fa fa-user" aria-hidden="true"></i>
                      Visualizza l'<b-link
                        :to="`/users?survey_id=${survey_id}&search=`">elenco dei partecipanti</b-link>
                    </p>
                  </b-col>
                  <b-col>
                    <b-dropdown>
                      <template #button-content>
                        Gestione Partecipanti
                        <b-spinner
                          v-if="waitImportCompanyUsers"
                          small></b-spinner>
                        <span class="sr-only">Loading...</span>
                      </template>
                      <b-dropdown-item
                        variant="primary"
                        size="sm"
                        @click="openImportParticipantsModal()"
                        title="(Re-)Importare gli impiegati per l'azienda con email, nome e cognome<">
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                        Importa partecipanti da Excel
                      </b-dropdown-item>

                      <b-dropdown-item @click="openImportCompanyUsersModal()">
                        <i class="fa fa-users" aria-hidden="true"></i>
                        Importa tutti gli utenti dell'azienda
                      </b-dropdown-item>

                      <b-dropdown-item @click="confirmDelete(survey_id)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Elimina tutti i partecipanti
                      </b-dropdown-item>

                      <b-dropdown-item v-if="0">
                        <!-- Todo: da implementare -->
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Elimina tutti i partecipanti che non hanno ancora
                        complilato
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-form-row>

                <b-form-row class="mt-3">
                  <b-col>
                    <label>e-mail mittente</label>
                    <p class="text-muted small">
                      <i class="fa fa-info" aria-hidden="true"></i> Il dominio
                      della mail che utilizzi deve essere configurato sul
                      server, altrimenti viene bloccato dai filtri antispam.
                    </p>
                  </b-col>
                  <b-col>
                    <b-input-group>
                      <b-form-input
                        v-model="sender_email"
                        size="sm"
                        trim
                        type="email"></b-form-input>
                      <b-button
                        variant="outline-primary"
                        @click="updateSurvey(false)">Aggiorna</b-button>
                    </b-input-group>
                  </b-col>
                </b-form-row>

                <b-form-row class="mt-3">
                  <b-col>
                    <p class="text-muted">
                      Invia automaticamente le mail ai partecipanti
                    </p>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="success"
                      size="sm"
                      @click="openSendTestInvitationModal()"
                      class="mr-2">
                      Invia questionario test
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </b-button>
                    <b-dropdown
                      size="sm"
                      text="e-mail di Invito"
                      variant="danger"
                      class="mr-2">
                      <b-dropdown-item @click="showInvitationTplModal = true">
                        <i class="fa fa-pencil" aria-hidden="true"></i> Modifica
                        testo mail di invito
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="openSendNotificationsModal('invitation')">
                        <i class="fa fa-envelope" aria-hidden="true"></i> Invia
                        Inviti Ora
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-form-row>

                <h4>Promemoria</h4>
                <b-form-row>
                  <b-col>
                    <label for="reminder-sb">1° Promemoria Compilazione dopo giorni</label>
                  </b-col>
                  <b-col>
                    <b-form-spinbutton
                      id="reminder-sb"
                      v-model="reminder"
                      min="1"
                      max="10"></b-form-spinbutton>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <label for="reminder2-sb">2° Promemoria Compilazione dopo giorni</label>
                  </b-col>
                  <b-col>
                    <b-form-spinbutton
                      id="reminder2-sb"
                      v-model="reminder2"
                      min="1"
                      max="60"></b-form-spinbutton>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-3">
                  <b-col>
                    <p class="text-muted">
                      <i class="fa fa-warning" aria-hidden="true"></i> La
                      piattaforma <b>non invia</b> promemoria automaticamente, i
                      numeri sopra sono solo di aiuto al mobility manager.
                    </p>
                  </b-col>
                  <b-col>
                    <b-dropdown
                      size="sm"
                      text="e-mail di Promemoria"
                      variant="danger"
                      class="mr-2">
                      <b-dropdown-item @click="showReminderTplModal = true">
                        <i class="fa fa-pencil" aria-hidden="true"></i> Modifica
                        testo e-mail di Promemoria
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="openSendNotificationsModal('reminder')">
                        <i class="fa fa-envelope" aria-hidden="true"></i> Invia
                        promemoria Ora
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group label="Piedipagina per mail e questionario (lascia vuoto per usare il default)" v-if="loggedUserIsAdmin && survey.survey_delivery_config" >
                <ckeditor
                  :editor="editor"
                  v-model="survey.survey_delivery_config.mail_footer"
                  :config="editorConfig"
                  :disabled="!loggedUserIsAdmin"></ckeditor>
              </b-form-group>
              
              <input type="hidden" name="id" :value="survey.id" />
              <b-form-row>
                <b-col>
                  <b-button
                    class="float-right"
                    @click.prevent="updateSurvey(false)"
                    variant="primary">Salva
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <template v-if="stats && !isTemplate()">
      <hr />

      <h3>
        Statistiche
        <span class="small">
          <b-badge variant="primary">Live</b-badge>
        </span>
      </h3>

      <div class="row">
        <div class="col-md-6 col-xl-3" v-for="s in statsCards" :key="s.title">
          <stats-card>
            <div
              class="icon-big text-center"
              :class="`icon-${s.type}`"
              slot="header">
              <i :class="s.icon"></i>
            </div>
            <div class="numbers" slot="content">
              <p>{{ s.title }}</p>
              <span v-if="s.percentage" class="small-percent">({{ s.percentage }}% )
              </span>
              {{ s.value }}
            </div>
            <div class="stats" slot="footer">
              <i :class="s.footerIcon"></i>
              <a v-if="s.footerUrl" :href="`${s.footerUrl}${survey_id}`">{{
                s.footerText
              }}</a>
              <span v-else>{{ s.footerText }}</span>
            </div>
          </stats-card>
        </div>
      </div>
    </template>

    <hr />

    <h3>
      Le domande
      <span class="float-right small" @click="current_section = null">
        Raggruppa <i class="fa fa-chevron-up" aria-hidden="true"></i>
      </span>
    </h3>

    <b-list-group>
      <b-list-group-item
        v-for="section in sections"
        :key="section.id"
        @click="openSection(section.id)"
        :active="section.id == current_section">
        <h5 class="heavy">{{ section.name }}</h5>
        <b-row>
          <b-col>
            {{ countQuestions(section.id) }}
            Domande
          </b-col>
          <b-col>
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
            {{ hiddenQuestions(section.id) }}
            Nascoste
          </b-col>
          <b-col>
            <i class="fa fa-lock" aria-hidden="true"></i>
            {{ compulsoryQuestions(section.id) }}
            Obbligatorie
          </b-col>
          <b-col>
            <b-button
              style="border: 0; background: none; color: #0055ff"
              @click="addQuestion(section.id)"
              title="Aggiungi una domanda a questa sezione"
              tooltip="Aggiungi una domanda a questa sezione"><i class="fa fa-plus" aria-hidden="true"></i>Aggiungi una
              domanda</b-button>
          </b-col>
        </b-row>

        <form action="" v-if="section.id == current_section">
          <hr />

          <b-card class="col-md-8">
            <b-button
              style="border: 0; background: none; color: #0055ff"
              @click="addQuestion(section.id)"
              title="Aggiungi una domanda a questa sezione"
              tooltip="Aggiungi una domanda a questa sezione"><i class="fa fa-plus" aria-hidden="true"></i>Aggiungi una
              domanda</b-button>
          </b-card>

          <b-card
            class="col-md-8"
            v-for="q in filterQuestions(section.id)"
            :key="q.id">
            <template v-slot:header>
              <h6 class="mb-0">
                {{ q.description }}
                <span class="light">(ID: {{ q.id }} - {{ q.name }})</span>
              </h6>
              <b-form-text>{{ q.long_description }}</b-form-text>
            </template>
            <p v-if="q._joinData.hidden" class="text-muted small">
              Questa domanda non comparirà nel questionario dell'utente. Per
              farla comparire usa il pulsante "MOSTRA"
            </p>
            <b-form-group v-if="!q._joinData.hidden">
              <b-form-input v-if="q.type == 'text'" name="q.id"></b-form-input>

              <div v-if="q.type == 'single'">
                <b-form-radio
                  v-for="(opt, index) in q.options"
                  :key="index"
                  :value="opt"
                  v-model.lazy="answer[q.id]">{{ opt }}
                </b-form-radio>
              </div>

              <b-form-checkbox-group
                stacked
                v-if="q.type == 'multiple'"
                v-model.lazy="answer[q.id]">
                <b-form-checkbox
                  v-for="(opt, index) in q.options"
                  :key="index"
                  :value="opt">{{ opt }}
                </b-form-checkbox>
              </b-form-checkbox-group>

              <div v-if="q.type == 'array'">
                <b-form-group
                  stacked
                  v-for="(group, index) in q.options.groups"
                  :key="index"
                  :label="group.label">
                  <b-form-select>
                    <b-form-select-option
                      v-for="(o, index2) in group.options"
                      :key="index2"
                      :value="o">
                      {{ o }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group v-if="q.options.allowOther">
                  <label>Altro</label>
                  <b-form-input> </b-form-input>
                </b-form-group>
              </div>

              <div v-if="q.type == 'map'">
                <map-question :survey_id="survey_id"></map-question>
              </div>
            </b-form-group>

            <template v-slot:footer>
              <span>
                <template
                  v-if="q.conditions && q.conditions.display == 'conditionally'">
                  <question-condition-info :question="q" />
                </template>
                <hr />
                <b-checkbox v-model="q._joinData.compulsory"  :disabled="!(loggedUser.isAdmin || loggedUser.isMomaManagerGenerale)" @change="toggleCompulsory(q.id)"> <i class="fa fa-lock" aria-hidden="true"></i>Domanda Necessaria <small class="text-muted">(Il mobility manager non può togliere questa domanda dal questionario)</small></b-checkbox>

                <b-button
                  v-if="loggedUser.isAdmin"
                  class="float-right"
                  size="sm"
                  variant="success"
                  @click="editQuestion(q.id)"><i class="fa fa-pencil" aria-hidden="true"></i>
                  Modifica
                </b-button>

                <b-spinner v-if="deletingQuestion" label="Spinning"></b-spinner>
                <b-button
                  :disabled="deletingQuestion"
                  v-if="loggedUser.isAdmin"
                  class="float-right"
                  size="sm"
                  variant="danger"
                  @click="deleteQuestion(q.id)"><i class="fa fa-trash" aria-hidden="true"></i>
                  Cancella
                </b-button>

                <b-button
                  v-if="
                    true /*loggedUser.isAdmin ||
                                        loggedUser.isMomaManagerGenerale ||
                                        (loggedUser.isMomaManagerAziendale && (
                                            (q._joinData.compulsory && q.creator_id == loggedUser.id) ||
                                            !q._joinData.compulsory
                                        ))*/
                  "
                  class="float-right"
                  size="sm"
                  @click="toggleHidden(q.id)">
                  <template v-if="!q._joinData.hidden">
                    <i class="fa fa-eye-slash" aria-hidden="true"></i> Nascondi
                  </template>
                  <template v-if="q._joinData.hidden">
                    <i class="fa fa-eye" aria-hidden="true"></i> Mostra
                  </template>
                </b-button>

                
              </span>
            </template>
          </b-card>
        </form>
      </b-list-group-item>
    </b-list-group>

    <!-- il backdrop della sidebar non funziona, ne aggiungo uno custom-->
    <div id="forced-sidebar-backdrop" :hidden="!sidebar_visible"></div>
    <b-sidebar
      id="sidebar-edit"
      :title="sidebar_question"
      v-model.lazy="sidebar_visible"
      bg-variant="dark"
      text-variant="light"
      right
      :shadow="true">
      <div class="px-3 py-2">
        <template v-if="!question.id">
          <b-form-group label="">
            <b-form-radio v-model="newQuestionType" name="new" value="new">Nuova</b-form-radio>
            <b-form-radio
              v-model="newQuestionType"
              name="existing"
              value="existing">Associa esistente</b-form-radio>
          </b-form-group>
        </template>

        <b-form
          @submit.stop.prevent
          v-if="!question.id && newQuestionType == 'existing'">
          <b-form-group
            description="Domanda"
            label="Domanda"
            label-for="input-type">
            <b-form-select
              v-model.lazy="newQuestionFromExistingForm.id"
              :options="unusedQuestionsForSelect"
              value-field="id"
              text-field="description"></b-form-select>
          </b-form-group>
          <b-form-group
            description="In quale sezione si colloca la domanda"
            label="Sezione"
            label-for="input-type">
            <b-form-select
              v-model.lazy="newQuestionFromExistingForm.section_id"
              :options="sections"
              value-field="id"
              text-field="name"></b-form-select>
          </b-form-group>
          <b-form-group
            description="Peso della domanda all'interno del gruppo (se ha maggiore peso compare dopo)"
            label="Peso"
            label-for="weight">
            <b-form-input
              type="range"
              v-model.lazy="newQuestionFromExistingForm.weight"
              min="-100"
              max="+100"></b-form-input>
          </b-form-group>
          <b-button
            @click="submitNewQuestionFromExisting"
            type="Submit"
            variant="primary">Salva</b-button>
        </b-form>

        <b-form
          @submit.stop.prevent
          class="question-edit"
          v-if="question.id || (!question.id && newQuestionType == 'new')">
          <b-form-group
            description="Codice Breve della Domanda (es: quanti-km)"
            label="Codice della Domanda"
            label-for="input-name">
            <b-form-input
              id="input-name"
              :value="questionCode(question.name)"
              @change="question.name = $event"
              maxlength="20"
              trim></b-form-input>
          </b-form-group>

          <fieldset>
            <b-form-group
              description="La domanda che vede l'utente"
              label="Titolo della domanda"
              label-for="input-description">
              <b-form-input
                id="input-description"
                :value="question.description"
                @blur="updateField('description', $event)"
                trim>
              </b-form-input>
            </b-form-group>
            <b-form-group
              v-if="canShowTranslation"
              label="EN 🇬🇧 > Titolo della domanda"
              label-for="input-description-en">
              <b-form-input              
                v-if="canShowTranslation"
                id="input-description-en"
                :value="question.en.description"
                @blur="updateField('en.description', $event)"
                trim>
              </b-form-input>
            </b-form-group>
          </fieldset>

          <fieldset>
            <b-form-group
              description="In questa casella vanno inserite spiegazioni approfondite"
              label="Descrizione Estesa"
              label-for="input-long-description">
              <b-form-textarea
                id="input-long-description"
                :value="question.long_description"
                @blur="updateField('long_description', $event)"
                trim></b-form-textarea>
            </b-form-group>
            <b-form-group
              v-if="canShowTranslation"
              label=" EN 🇬🇧 > Descrizione Estesa"
              label-for="input-long-description-en">
              <b-form-textarea
                id="input-long-description-en"
                v-if="canShowTranslation"
                :value="question.en.long_description"
                @blur="updateField('en.long_description', $event)"
                trim>
              </b-form-textarea>
            </b-form-group>
          </fieldset>

          <b-form-checkbox
            inline
            id="input-compulsory-answer"
            v-model="question.compulsory_answer">Risposta Obbligatoria</b-form-checkbox>

          <b-form-group
            description="Il tipo di domanda"
            label="Tipo di Domanda"
            label-for="input-type"
            class="mt-2">
            <b-form-select
              v-model.lazy="question.type"
              :options="question_options"></b-form-select>
          </b-form-group>
          <b-form-group
            description="In quale sezione si colloca la domanda"
            label="Sezione"
            label-for="input-type">
            <b-form-select
              v-model.lazy="question._joinData.section_id"
              :options="sections"
              value-field="id"
              text-field="name"></b-form-select>
          </b-form-group>

          <!-- affinchè le opzioni si aggiornino correttamente nei campi di tipo devo
                    renderizzarlo sempre (nascondendolo opportunamente) altrimenti in certe situazioni
                    (es. prima setto 'array', poi 'text', poi 'single') mi ritrovo opzioni errate (array renderizzati)-->
          <b-form-group
            :hidden="
              ['single', 'multiple', 'array'].indexOf(question.type) == -1
            "
            description="Elenco di opzioni da presentare all'utente"
            label="Opzioni"
            label-for="input-options">
            <question-option-editor
              :canShowTranslation="canShowTranslation"
              :key="question.id"
              :type="question.type"
              :trans.sync="question.en.options"
              v-model.lazy="question.options"
              v-if="question.en.options!==undefined"/>
          </b-form-group>
          <input type="hidden" name="id" :value="question.id" />

          <div>
            <b-button v-b-toggle.collapse-1 variant="primary">Avanzate</b-button>
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <b-form-group
                  description="Peso della domanda all'interno del gruppo (se ha maggiore peso compare dopo)"
                  :label="`Peso: ${question._joinData.weight}`"
                  label-for="weight">
                  <b-form-input
                    type="range"
                    v-model.lazy="question._joinData.weight"
                    min="-100"
                    max="+100"></b-form-input>
                </b-form-group>

                <question-condition-editor
                  :key="question.id"
                  v-model="question.conditions"
                  :canShowTranslation="canShowTranslation"
                  :id="question.id"/>
              </b-card>
            </b-collapse>
          </div>

          <br />
          <b-button @click="submitQuestion" type="Submit" variant="primary">Salva</b-button>
        </b-form>
      </div>
    </b-sidebar>

    <b-modal id="bv-modal-confirm-delete" variant="danger">
                <template v-slot:modal-title> Conferma cancellazione </template>
                <div>
                    <p>Vuoi davvero cancellare tutti partecipanti?</p>
                    <p>
                        Proseguendo verrà rimossi tutti partecipanti del questionario.<br />
                        Operazione irreversibile
                    </p>
                </div>
                <template v-slot:modal-footer>
                    <div class="w-100">
                        <b-button @click="$bvModal.hide('bv-modal-confirm-delete')" class="mr-1">Annulla</b-button>
                        <b-button @click="delSurvey(current_survey)" variant="danger"><span class="ti-trash"></span> Elimina</b-button>
                    </div>
                </template>
      </b-modal>

    <b-modal
      size="lg"
      v-model="showInvitationTplModal"
      title="Messaggio di Invito">
      <b-form-group label="Oggetto">
        <b-form-input v-model="invitation_subject"></b-form-input>
      </b-form-group>
      <b-form-group label="Messaggio">
        <div class="text-muted" v-pre>Tag Utilizzabili {{{nome}}}, {{{titolo}}}, {{{link}}}</div>
        <ckeditor
          id="invitation-template-textarea"
          :editor="editor"
          v-model="invitation_tpl"
          :config="editorConfig"
          rows="3"
          max-rows="6">
        </ckeditor>
        
      </b-form-group>

      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="
              updateSurvey(false);
              showInvitationTplModal = false;
            ">
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      size="lg"
      v-model="showReminderTplModal"
      title="Messaggio di Promemoria">
      <b-form-group label="Oggetto">
        <b-form-input v-model="reminder_subject"></b-form-input>
      </b-form-group>
      <b-form-group label="Messaggio">
        <div class="text-muted" v-pre>Tag Utilizzabili {{{nome}}}, {{{titolo}}}, {{{link}}}</div>
        <ckeditor
          id="reminder-template-textarea"
          :editor="editor"
          v-model="reminder_tpl"
          :config="editorConfig"
          rows="3"
          max-rows="6">
        </ckeditor>       
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="
              updateSurvey(false);
              showReminderTplModal = false;
            ">
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="showSendTestInvitationModal"
      title="Invia questionario di test">
      <p>
        <i>Usa questa funzionalità per inviare una mail di test (a te stesso o
          al tuo gruppo), prima di fare l'invio massivo del questionario.</i>
      </p>
      <b-form action="">
        <b-form-group label="Nome">
          <b-form-input
            v-model="testRecipient.name"
            placeholder="Nome e Cognome"></b-form-input>
        </b-form-group>
        <b-form-group label="Email">
          <b-form-input
            v-model="testRecipient.email"
            placeholder="miamail@example.com"></b-form-input>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            :disabled="!(testRecipient.name && testRecipient.email)"
            variant="primary"
            size="sm"
            class="float-right"
            @click="sendTestInvitation()">
            Invia
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="showSendTestInvitationModal = false">
            Annulla
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="showSendNotificationsModal" title="Invia notifiche">
      <div v-if="loadingNotificationsModalContent" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div v-if="!loadingNotificationsModalContent">
        <p v-if="!canSendNotifications">
          <span v-if="notificationsTarget == 'invitation'">Non ci sono inviti da inviare</span>
          <span v-if="notificationsTarget == 'reminder'">Non ci sono promemoria da inviare</span>
        </p>
        <div v-if="canSendNotifications">
          <div v-if="notificationsTarget == 'invitation'" class="text-center">
            Si stanno per inviare
            <b>{{ stats.participants.sendable_invitation_num }}</b>
            inviti
          </div>
          <div v-if="notificationsTarget == 'reminder'" class="text-center">
            Si stanno per inviare
            <ul>
              <li v-if="stats.participants.sendable_first_reminder_num">
                <b>{{ stats.participants.sendable_first_reminder_num }}</b>
                promemoria per la prima volta
              </li>
              <li v-if="stats.participants.sendable_second_reminder_num">
                <b>{{ stats.participants.sendable_second_reminder_num }}</b>
                promemoria per la seconda volta
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100" v-if="canSendNotifications">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="sendNotifications()">
            Invia
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="showSendNotificationsModal = false">
            Annulla
          </b-button>
        </div>
        <div class="w-100" v-if="!canSendNotifications">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="showSendNotificationsModal = false">
            Chiudi
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="import-participants-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      size="xl"
      scrollable>
      <template #modal-title> Importa partecipanti da Excel </template>
      <div class="">
        <p class="text-muted">
          I contatti verranno aggiunti ai contatti esistenti solo se hanno una
          mail diversa da quelle già esistenti.
        </p>
        <b-form>
          <input type="hidden" v-model="actions.importParticipants.surveyId" />
          <b-form-file
            :accept="actions.importParticipants.supportedFileTypes"
            v-model="actions.importParticipants.file"></b-form-file>
        </b-form>
        <b-progress :max="actions.importParticipants.progress.max" class="mb-3">
          <b-progress-bar
            variant="success"
            :value="actions.importParticipants.progress.success"
            animated
            show-progress></b-progress-bar>
          <b-progress-bar
            variant="danger"
            :value="actions.importParticipants.progress.error"
            animated
            show-progress></b-progress-bar>
        </b-progress>
        <div>
          <p style="color: green">
            {{ actions.importParticipants.log.success }}
          </p>
          <p
            style="color: red"
            v-html="actions.importParticipants.log.error"></p>
        </div>
      </div>
      <b-alert show variant="warning" style="font-size: 90%">
        <b-row>
          <b-col>
            <h3>
              <b-icon icon="exclamation-circle-fill"></b-icon> Struttura del
              file da importare
            </h3>
            <ul>
              <li>prima riga: header colonne (skipped)</li>
              <li>
                colonne importate
                <ul>
                  <li>A (email)</li>
                  <li>B (nome)</li>
                  <li>C (cognome)</li>
                </ul>
              </li>
            </ul>
            <!--<b-button variant="light" size="sm">Scarica file di esempio</b-button>-->
          </b-col>
        </b-row>
      </b-alert>
      <template #modal-footer>
        <b-button @click="stopImportParticipants()">
          <span v-if="actions.importParticipants.importing">Stop</span>
          <span v-else>Chiudi</span>
        </b-button>
        <b-button
          :disabled="
            !actions.importParticipants.file ||
            actions.importParticipants.importing
          "
          variant="primary"
          @click="importParticipants()">
          <b-spinner
            v-if="actions.importParticipants.importing"
            variant="primary"
            label="Spinning"></b-spinner>
          Importa
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="import-employees-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      size="xs"
      scrollable
      v-model="editingQuestion">
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
      <template #modal-footer><span></span></template>
    </b-modal>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "/ckeditor5/build/ckeditor";
import UserService from "@/services/user.service";
import { StatsCard } from "@/components/index";
import Util from "@/mixing/util";
import MapQuestion from "@/components/MapQuestion.vue";
import XlsService from "@/services/xls.service";
import QuestionService from "@/services/question.service";
import QuestionOptionEditor from "@/components/QuestionOptionEditor";
import QuestionConditionEditor from "@/components/QuestionConditionEditor";
import QuestionConditionInfo from "@/components/QuestionConditionInfo";

import slugify from "slugify";
import _ from "lodash";
import { Map } from "leaflet";
import { async } from "q";

// todo: come sarebbe bello usare un enum di typescript ...
const SENDING_MODE = {
	n: "nominale",
	z: "anonimizzato",
	a: "anonimo",
};

const EMPTY_QUESTION = {
	id: "",
	name: "",
	description: "",
	long_description: "",
	type: "",				
	options: [],        
	section_id: null,
	compulsory_answer: false,
	en: {
		description: "",
		long_description: "",
		options: [],
	},
	_joinData: {},
};

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
	components: {
		StatsCard,
		MapQuestion,
		QuestionOptionEditor,
		QuestionConditionEditor,
		QuestionConditionInfo,
		// Use the <ckeditor> component in this view.
		ckeditor: CKEditor.component,
	},
	mixins: [Util],
	name: "QuestionariEdit",
	data() {
		return {
			reminder: 2,
			reminder2: 5,
			serverPath: process.env.VUE_APP_ROOT,
			logofile: null,
      logofileSrc: null,
      logofileDir: process.env.VUE_APP_LOGO,
			amb:process.env.VUE_APP_AMB,
			//logdir: null, 
			imgKey: Date.now(),
			// todo: i template default letti dal server
			// nota: triple-mustache per fare il corretto rendering (non-escaped) dell'html
			invitation_tpl: "",
			reminder_tpl: "",
			invitation_subject: "",
			reminder_subject: "",
			showInvitationTplModal: false,
			showReminderTplModal: false,
			showSendTestInvitationModal: false,
			showSendNotificationsModal: false,
			loadingNotificationsModalContent: false,
			notificationsTarget: null,
			canSendNotifications: false,
			interrompi: false,
			survey: {
				welcome: null,
				survey_delivery_config : {
					mail_footer: "",
				},
			},
			answer: [],
			sections: [],
			companies: [],
			survey_id: null,
			sidebar_visible: false,
			sidebar_question: "",
			current_section: null,
			sender_email: this.help_mail,
			question_options: [],      
			question: EMPTY_QUESTION,
			testRecipient: {
				name: null,
				email: null,
			},
			stats: null,
			statsCards: [
				{
					type: "primary",
					icon: "ti-view-list-alt",
					title: "Compilati",
					percentage: 0,
					value: "",
					footerText: "Dettaglio",
					footerIcon: "ti-arrow-right",
					footerUrl: "/questionari/delivery-stats/",
				},
				{
					type: "success",
					icon: "ti-email",
					title: "Inviti",
					value: "",
					footerText: "Errori",
					footerIcon: "ti-flag",
				},
				{
					type: "warning",
					icon: "ti-email",
					title: "Primo Promemoria",
					value: "",
					footerText: "Aggiornato ora",
					footerIcon: "ti-reload",
				},
				{
					type: "danger",
					icon: "ti-email",
					title: "Secondo Promemoria",
					value: "",
					footerText: "Aggiornato ora",
					footerIcon: "ti-reload",
				},
			],
			statsIntervalId: null,
			surveyAnonymousLink: "",
			actions: {
				importParticipants: {
					supportedFileTypes: ".xls, .xlsx",
					surveyId: null,
					file: null,
					importing: false,
					log: [],
					progress: {
						max: 0,
						success: 0,
						error: 0,
					},
				},
			},
			newQuestionType: "new",
			unusedQuestions: [],
			unusedQuestionsForSelect: [],
			newQuestionFromExistingForm: {
				id: null,
				section_id: null,
				weight: null,
			},
			editingQuestion: false,
			loggedUser: {},
			testLink: "",
			weightOpts: _.range(-50, 100),
			deletingQuestion: false,
			waitImportCompanyUsers: false,
			editor: ClassicEditor,
			editorConfig: {
				language: "it",         				
				toolbar: {
					items: [
						"heading", 
						"fontsize", "fontcolor","|",
						"alignment", "|",  "insertImage" ,				 
						"bold", "italic", "strikethrough", "underline", "subscript", "superscript", "|",
						"link", "|",
						"outdent", "indent", "|",
						"bulletedList", "numberedList", "todoList", "|", "horizontalLine",
					],
				},
			},
		};
	},
	computed: {
		isAnonymous() {
			return (
				this.survey.sending_mode == "a" || this.survey.sending_mode == null
			);
		},
		isNominal() {
			return this.survey.sending_mode == "n";
		},
		isAnonimized() {
			return this.survey.sending_mode == "z";
		},
    hasLogofile() {
      return !!this.logofile;
    },
	},
	watch: {
		stats: function () {
			this.canSendNotifications =
        this.stats &&
        this.notificationsTarget &&
        ((this.notificationsTarget == "invitation" &&
          this.stats.participants.sendable_invitation_num > 0) ||
          (this.notificationsTarget == "reminder" &&
            this.stats.participants.sendable_first_reminder_num +
              this.stats.participants.sendable_second_reminder_num >
              0));
			if (this.stats) {
				this.statsCards[0].value = `${this.stats.participants.survey_completed_num}/${this.stats.participants.total_num}`;
				this.statsCards[1].value = `${this.stats.participants.sent}/${this.stats.participants.invitation_sent_num}`;
				this.statsCards[1].footerText = `${this.stats.participants.errors} Errori di invio`;
				this.statsCards[2].value = `${this.stats.participants.first_reminder_sent_num}`;
				this.statsCards[3].value = `${this.stats.participants.second_reminder_sent_num}`;
				if (this.stats.participants.total_num > 0) {
					this.statsCards[0].percentage = (
						(this.stats.participants.survey_completed_num /
              this.stats.participants.total_num) *
            100
					).toFixed(2);
				} else {
					this.statsCards[0].percentage = "0/0";
				}
			}
		},
    logofile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.logofileSrc = value;
            })
            .catch(() => {
              this.logofileSrc = null;
            });
        } else {
          this.logofileSrc = null;
        }
      }
    },
	},
	mounted: async function () {
		try {
			let response = await UserService.getStats(this.$route.params.survey_id);
			this.stats = response.data.stats;
		} catch (e) {
			this.stats = null;
			console.log(e);
		}
		this.statsIntervalId = setInterval(async () => {
			try {
				let response = await UserService.getStats(this.$route.params.survey_id);
				this.stats = response.data.stats;
			} catch (e) {
				this.stats = null;
				console.log(e);
			}
		}, 30000);

		//Importo le costanti
		let self = this;
		let surv_default = await import(
			"@/theme/" + process.env.VUE_APP_THEME + "/survey-defaults"
		);
		self.survey.welcome = surv_default.welcome;
		self.invitation_tpl = surv_default.invitation_tpl;
		self.invitation_subject = surv_default.invitation_subject;
		self.reminder_subject = surv_default.reminder_subject;

		UserService.getSurveyEdit(this.survey_id).then(
			(response) => {
				this.survey = response.data.survey;
				if (self.survey.welcome == null || self.survey.welcome == "") {
					self.survey.welcome = surv_default.welcome;
				}

				QuestionService.initQuestions(this.survey.questions);
				if (this.survey.survey_delivery_config) {
					this.reminder =
            this.survey.survey_delivery_config.days_before_first_reminder;
					this.reminder2 =
            this.survey.survey_delivery_config.days_before_second_reminder;
					this.invitation_tpl =
            this.survey.survey_delivery_config.invitation_template;
					this.reminder_tpl =
            this.survey.survey_delivery_config.reminder_template;
					this.invitation_subject =
            this.survey.survey_delivery_config.invitation_subject;
					this.reminder_subject =
            this.survey.survey_delivery_config.reminder_subject;
					this.sender_email = this.survey.survey_delivery_config.sender_email;
					if (this.survey.survey_delivery_config.is_active) {
						this.survey_sending_mode = "n";
					}
					if (!this.survey.survey_delivery_config.mail_footer) {
						this.survey.survey_delivery_config.mail_footer = "";
					}
				} 
			},
			(error) => this.showError(error)
		);
    
	},

	beforeDestroy: function () {
		if (this.statsIntervalId) {
			clearInterval(this.statsIntervalId);
		}
    
	},
 

	methods: {
		questionCode: function (n) {      
			return slugify(n);
		},
		isTemplate: function () {
			return this.survey && this.survey.version_tag == "template";
		},
		async delSurvey(survey_id) {
			try {
				var participants = await UserService.deleteAllSurveyParticipnats(survey_id);
			} catch (error) {
				this.showError(error);
			}
      console.log(participants);
      this.$bvToast.toast(participants.data.participants+" partecipanti cancellati correttamente.", {
						// title: "No Uploads",
						variant: "success",
						autoHideDelay: 2000,
						appendToast: true,
					});
			this.current_survey = null;
			this.$bvModal.hide("bv-modal-confirm-delete");
		},
		confirmDelete(survey_id) {
			this.current_survey = survey_id;
			this.$bvModal.show("bv-modal-confirm-delete");
		},
		copySurveyAnonymousLink: function () {
			let anonLinkToCopy = document.querySelector("#anon-link");
			anonLinkToCopy.setAttribute("type", "text");
			anonLinkToCopy.select();

			try {
				var successful = document.execCommand("copy");
				this.$bvToast.toast(
					successful ? "Copiato negli appunti" : "Impossibile copiare",
					{
						variant: successful ? "success" : "danger",
						title: successful ? "Copiato negli appunti" : "Impossibile copiare",
						autoHideDelay: 2000,
						appendToast: true,
					}
				);
			} catch (err) {
				console.log(err);
				this.$bvToast.toast("Impossibile copiare il link negli appunti", {
					variant: "danger",
					title: "Impossibile copiare",
					autoHideDelay: 2000,
					appendToast: true,
				});
			}
		},

		filterQuestions(section_id) {
			if (this.survey.questions == undefined) {
				return [];
			}
			const questionsInSection = this.survey.questions.filter(function (m) {
				return m._joinData.section_id == section_id;
			});
			questionsInSection.sort((q1, q2) => {
				let w1 = q1._joinData && q1._joinData.weight ? q1._joinData.weight : 0;
				let w2 = q2._joinData && q2._joinData.weight ? q2._joinData.weight : 0;
				return w1 - w2;
			});
			return questionsInSection;
		},
		hiddenQuestions(section_id) {
			if (this.survey.questions == undefined) {
				return 0;
			}
			return this.survey.questions.filter(function (m) {
				return m._joinData.section_id == section_id && m._joinData.hidden;
			}).length;
		},
		compulsoryQuestions(section_id) {
			if (this.survey.questions == undefined) {
				return 0;
			}
			return this.survey.questions.filter(function (m) {
				return m._joinData.section_id == section_id && m._joinData.compulsory;
			}).length;
		},
		countQuestions(section_id) {
			if (this.survey.questions == undefined) {
				return 0;
			}
			return this.survey.questions.filter(function (m) {
				return m._joinData.section_id == section_id;
			}).length;
		},
		async editQuestion(qid) {
			this.sidebar_visible = true;
			this.sidebar_question = `Modifica domanda ${qid}`;

			this.question = this.survey.questions.find((x) => x.id === qid);
			let res = await UserService.getQuestionTranslations(qid);
			let translations = res.data.t;
			if (translations.en !== undefined) {
				this.question.en = translations.en;
			}

			if (this.question.en.options == undefined){
				this.question.en.options = this.question.options;      
			}

			//console.log("en.options->", this.question.en.options);
			//console.log("Question-->", this.question);
			this.$nextTick(() => {
				this.$forceUpdate();
			});
		},
		deleteQuestion: async function (qid) {
			this.deletingQuestion = true;
			try {
				const res = await UserService.removeQuestionFromSurvey(
					this.survey.id,
					qid
				);
				if (res.data.errorMsg) {
					throw new Error(res.data.errorMsg);
				}
				this.$bvToast.toast("Domanda rimossa correttamente dal questionario", {
					title: "Cancellazione OK",
					variant: "success",
					autoHideDelay: 2000,
					appendToast: true,
				});
				const updatedSurvey = await UserService.getSurveyEdit(this.survey.id);
				this.survey.questions = updatedSurvey.data.survey.questions;
				QuestionService.initQuestions(this.survey.questions, true);
			} catch (e) {
				console.log(e);
				this.showError(e);
			} finally {
				this.deletingQuestion = false;
			}
		},
		toggleHidden: async function (qid) {
			this.editingQuestion = true;
			try {
				this.sidebar_visible = false;
				this.question = this.survey.questions.find((x) => x.id === qid);
				this.question._joinData.hidden = !this.question._joinData.hidden;
				//L'attributo hidden non è della domanda, ma della relazione questions_survey
				await UserService.toggleQuestionVisibility(
					qid,
					this.survey.id,
					this.question._joinData.hidden
				);
			} catch (e) {
				console.log(e);
				this.showError(e);
			} finally {
				this.editingQuestion = false;
			}
		},
		toggleCompulsory: async function (qid) {
			this.editingQuestion = true;
			try {
				this.sidebar_visible = false;
				this.question = this.survey.questions.find((x) => x.id === qid);
				//L'attributo hidden non è della domanda, ma della relazione questions_survey
				await UserService.toggleQuestionCompulsory(
					qid,
					this.survey.id,
					this.question._joinData.compulsory
				);
			} catch (e) {
				console.log(e);
				this.showError(e);
			} finally {
				this.editingQuestion = false;
			}
		},
		openSection(section_id) {
			this.current_section = section_id;
		},
    updateLogo:  async function (silent){
      
      try {
				await UserService.updateLogo(
					{
						logofile: this.logofile,
					},
					this.survey.id
				);
				// todo: il messaggio di risposta dal server per maggiore configurabilità
				if (!silent) {
					this.$bvToast.toast("I dati sono stati salvati correttamente", {
						title: "Salvataggio ok",
						autoHideDelay: 2000,
						appendToast: true,
					});
				}
			} catch (e) {
				console.log(e);
			}
    },

		updateSurvey: async function (silent) {
			if(this.logofile!= undefined){
				this.survey.logo=this.logofileDir+this.survey.id+
        this.logofile.name.substring(this.logofile.name.length-4);
        await this.updateLogo(true);
        this.logofile= null;
        this.imgKey=Date.now();
        
			}

			// prepara/aggiorna la configurazione di invio
			if (!this.survey.survey_delivery_config) {
				this.survey.survey_delivery_config = {};
			}
			this.survey.survey_delivery_config = {
				...this.survey.survey_delivery_config,
				days_before_first_reminder: this.reminder,
				days_before_second_reminder: this.reminder2,
				invitation_template: this.invitation_tpl,
				reminder_template: this.reminder_tpl,
				invitation_subject: this.invitation_subject,
				reminder_subject: this.reminder_subject,
				sender_email: this.sender_email,
				is_active: this.sending_mode === SENDING_MODE.platform ? 1 : 0,
			};
			try {
				await UserService.updateSurvey(
					{
						...this.survey,
						questions: undefined,
					},
					this.survey.id
				);
				// todo: il messaggio di risposta dal server per maggiore configurabilità
				if (!silent) {
					this.$bvToast.toast("I dati sono stati salvati correttamente", {
						title: "Salvataggio ok",
						autoHideDelay: 2000,
						appendToast: true,
					});
				}
			} catch (e) {
				console.log(e);
			}

		},
		updateField: function (field, e) {
			//Questo serve per gestire i campi lingua
			let pre = field.split(".")[0];
			let post = field.split(".")[1];

			if (post) {
				this.question[pre][post] = e.target.value;
			} else {
				this.question[field] = e.target.value;
			}
		},

		showQuestions() {
			this.show_questions = !this.show_questions;
		},
		openSendTestInvitationModal() {
			this.testRecipient = {
				email: localStorage.getItem("testReceipientEmail"),
				name: localStorage.getItem("testReceipientName"),
			};
			this.showSendTestInvitationModal = true;
		},
		async openSendNotificationsModal(target) {
			this.notificationsTarget = target;
			this.showSendNotificationsModal = true;
			this.loadingNotificationsModalContent = true;
			try {
				let response = await UserService.getStats(this.survey.id);
				this.stats = response.data.stats;
			} catch (e) {
				this.stats = null;
				console.log(e);
			} finally {
				this.loadingNotificationsModalContent = false;
			}
		},
		async sendTestInvitation() {
			localStorage.setItem("testReceipientEmail", this.testRecipient.email);
			localStorage.setItem("testReceipientName", this.testRecipient.name);
			//console.log(this.testRecipient);
			this.showSendTestInvitationModal = false;
			try {
				// salva sempre prima di procedere (potrei aver modificato template o altro senza cliccare su salva)
				await this.updateSurvey(true);
				await UserService.sendTestInvitation(
					this.testRecipient,
					this.survey.id
				);
				this.$bvToast.toast("Invito di test inviato correttamente", {
					title: "Invio ok",
					autoHideDelay: 2000,
					appendToast: true,
				});
			} catch (e) {
				console.log(e);
				this.showError(e);
			}
		},
		async sendNotifications() {
			this.showSendNotificationsModal = false;
			try {
				await UserService.sendNotifications(
					this.survey.id,
					this.notificationsTarget
				);
				this.$bvToast.toast("Il processo di invio è cominciato correttamente", {
					title: "Invio ok",
					autoHideDelay: 2000,
					appendToast: true,
				});
			} catch (e) {
				console.log(e);
				this.showError(e);
			}
		},
		submitNewQuestionFromExisting: async function () {
			if (
				!this.newQuestionFromExistingForm.id ||
        !this.newQuestionFromExistingForm.section_id
			) {
				this.$bvToast.toast(
					"Selezionare una domanda e una sezione per procedere",
					{
						title: "Errore",
						variant: "warning",
						autoHideDelay: 2000,
						appendToast: true,
					}
				);
				return;
			}
			this.question = {
				...this.unusedQuestions.find(
					(q) => q.id == this.newQuestionFromExistingForm.id
				),
				// la domanda esiste già, NON modifico la sua section originale (modifico solo quella nella survey)
				_joinData: {
					hidden: 0,
					compulsory: 0,
					section_id: this.newQuestionFromExistingForm.section_id,
					survey_id: this.survey.id,
					weight: this.newQuestionFromExistingForm.weight,
				},
			};
			await this.submitQuestion();
		},
		submitQuestion: async function () {
			//console.log(this.question);
			// se posso editare i campi della domanda, validala
			if (
				this.loggedUser.isAdmin ||
        this.loggedUserIsMomaArea ||
        (this.loggedUserIsMomaManagerAziendale &&
          this.loggedUser.id == this.question.creator_id)
			) {
				// valida la domanda
				let validationErrors = [];
				if (!this.question.name) {//this.newquestion=false;
					validationErrors.push("Specificare un codice");
				}else{
          let q= (await UserService.getAllQuestions()).data.questions.find((x) => x.name === this.question.name);
          if(!this.sidebar_question.includes("Modifica")){
            if (q && this.newQuestionType.includes("new")) {
          //this.newquestion=false;
            validationErrors.push("Il codice esiste già");
          }
        }
        }
        
				if (!this.question.description) {
					validationErrors.push("Specificare una descrizione");
				}
				if (!this.question.type) {
					validationErrors.push("Specificare un tipo");
				}
				if (
					this.question.type &&
          ["single", "multiple"].indexOf(this.question.type) != -1
				) {
					try {
						let options = this.question.options;
						//console.log(options);            
						if (!Array.isArray(options) || options.length == 0) {
							validationErrors.push("Specificare almeno una opzione");
						} else {
							if (options.filter((o) => String(o).length == 0).length > 0) {
								validationErrors.push("Una o più opzioni sono vuote");
							}
						}
					} catch (e) {
						validationErrors.push("Specificare almeno una opzione");
					}
				}
				if (this.question.type == "array") {
					try {
						let options = this.question.options;
						if (!Array.isArray(options.groups) || options.groups.length == 0) {
							validationErrors.push("Specificare almeno un gruppo di opzioni");
						} else {
							options.groups.forEach((group) => {
								if (String(group.label).length == 0) {
									validationErrors.push(
										"Specificare una label per il gruppo di opzioni"
									);
								}
								if (
									!Array.isArray(group.options) ||
                  group.options.length == 0
								) {
									validationErrors.push(
										"Specificare almeno una opzione per ogni gruppo"
									);
								}
								if (
									group.options.filter((o) => String(o).length == 0).length > 0
								) {
									validationErrors.push("Una o più opzioni sono vuote");
								}
							});
						}
					} catch (e) {
						validationErrors.push("Specificare almeno una opzione");
					}
				}
				if (validationErrors.length) {
					this.$bvToast.toast(validationErrors.join(", "), {
						title: "Attenzione",
						variant: "warning",
						autoHideDelay: 2000,
						appendToast: true,
					});
					return;
				}
			}

			this.editingQuestion = true;
			try {
				if (!this.question.id) {
					// in caso di aggiunta di nuova domanda il form è binded sulla section della domanda, devo
					// sempre aggiornare la section della domanda generale nel caso sia cambiata
					this.question.section_id = this.question._joinData.section_id;
					this.question.survey_id= this.question._joinData.survey_id;
				}
				const result = await UserService.editQuestionFromSurvey(this.question);
				if (result.data.errorMsg != false) {
					throw new Error(result.data.errorMsg);
				}
				// per semplicità e pulizia aggiorno facendo un full refresh
				const updatedSurvey = await UserService.getSurveyEdit(this.survey.id);
				this.survey.questions = updatedSurvey.data.survey.questions;
				QuestionService.initQuestions(this.survey.questions, true);
				this.sidebar_visible = false;
			} catch (e) {
				this.showError(e);
				console.log(e);
			} finally {
				this.editingQuestion = false;
			}
		},
		//Aggiungo una domanda alla sezione dove l'utente ha fatto click
		async addQuestion(section_id) {
			this.newQuestionFromExistingForm ={
				// resetta sempre
				id: null,
				section_id,
				weight: null,        
			}; 
			try {
				// fai sempre un refresh con le domande correnti non usate
				this.unusedQuestions = (
					await UserService.getAllUnusedQuestions(this.survey.id)
				).data.questions;
				QuestionService.initQuestions(this.unusedQuestions, true); // importante!
				//console.log(this.unusedQuestions);
				// ri-mappo su di un oggetto più semplice, altrimenti da dei problemi il select (è graficamente bloccato)
     		this.unusedQuestionsForSelect = this.unusedQuestions.map((q) => ({
					// ri-mappo su di un oggetto più semplice, altrimenti da dei problemi il select (è graficamente bloccato)
					id: q.id,
					name: q.name,
					type: q.type,
					description:
            q.id +
            ". " +
            q.name +
            " - " +
            (String(q.description).length > 40
            	? String(q.description).substring(0, 40) + "..."
            	: q.description),
				}));
				this.unusedQuestionsForSelect.sort(function (a, b) {
					return a.name > b.name;
				});
			} catch (e) {
				console.log(e);
				this.showError(e);
			}

			this.newQuestionType = "existing";
			this.question = EMPTY_QUESTION;
			this.question.name = "Nuova Domanda";
			this.section_id= section_id;
			this._joinData = {
				survey_id: this.survey_id,
				section_id: section_id,
				hidden: 0,
				compulsory: 0,
			};      
			this.sidebar_question = "Nuova Domanda";
			//Mostro il pannello di salvataggio
			this.sidebar_visible = true;
		},
		async openImportCompanyUsersModal() {
			this.$bvModal
				.msgBoxConfirm(
					"Sei sicuro di voler portare tutti gli utenti di questa azienda tra i partecipanti di questo questionario?"
				)
				.then(async (value) => {
					if (value) {
						this.waitImportCompanyUsers = true;
						await UserService.importCompanyUsers(this.survey_id);
						alert("nuovi utenti dell'azienda importati con successo");
						this.survey.participants_num = 1;
						this.waitImportCompanyUsers = false;
					}
				})
				.catch((e) => {
					this.showError(e);
				});
		},
		openImportParticipantsModal() {
			this.actions.importParticipants.file = null; // important, reset
			this.actions.importParticipants.surveyId = this.survey_id;
			this.actions.importParticipants.log.success = null;
			this.actions.importParticipants.log.error = null;
			this.actions.importParticipants.importing = false;
			this.actions.importParticipants.progress = {
				max: 0,
				success: 0,
				error: 0,
			};
			this.interrompi = false;
			this.$bvModal.show("import-participants-modal");
		},
		async importParticipants() {
			this.interrompi = false;
			this.actions.importParticipants.importing = true;
			try {
				const content = await XlsService.read(
					this.actions.importParticipants.file
				);
				const sheets = Object.keys(content);
				const sheetContent = content[sheets[0]]; // per convenzione elaboro solo il primo sheet che trovo
				this.actions.importParticipants.progress.max = sheetContent.length - 1;

				for (let i = 1; i < sheetContent.length; i++) {
					let res = await UserService.importParticipant(
						sheetContent[i],
						this.survey_id
					);
					if (res.data.errorMsg == false) {
						this.actions.importParticipants.log.success = `Importata la riga ${i}`;
						this.actions.importParticipants.progress.success++;
					} else {
						this.actions.importParticipants.log.error +=
              res.data.errorMsg + "<br>";
						this.actions.importParticipants.progress.error++;
					}
					if (this.interrompi) {
						this.interrompi = false;
						return;
					}
				}
				this.survey.participants_num =
          this.actions.importParticipants.progress.success; // qualunque valore andrebbe bene, basta che non sia 0 per aggiornare la vista
			} catch (e) {
				console.log(e);
				this.showError(e);
				this.$bvModal.hide("import-participants-modal");
			} finally {
				this.actions.importParticipants.importing = false;
			}
		},
		stopImportParticipants() {
			if (!this.actions.importParticipants.importing) {
				this.interrompi = false;
				this.$bvModal.hide("import-participants-modal");
			} else {
				this.interrompi = true;
			}
		},
	},
	created() {
		this.loggedUser = {
			id: this.loggedUserId,
			isAdmin: this.loggedUserIsAdmin,
			isMomaManagerGenerale: this.loggedUserIsMomaArea,
			isMomaManagerAziendale: this.loggedUserIsMomaManagerAziendale,
		};

		this.survey_id = parseInt(this.$route.params.survey_id);

		let tokens = window.location.href.split("/");
		tokens.pop();
		tokens.pop();
		this.surveyAnonymousLink = [...tokens, "fill", this.survey_id, "anon"].join(
			"/"
		);
		let self = this;

		UserService.getSections()
			.then((response) => {
				this.sections = response.data.sections;
			})
			.catch((error) => this.showError(error));

		UserService.getQuestionTypes()
			.then((response) => {
				this.question_options = response.data.question_types;
			})
			.catch((error) => this.showError(error));

		//Se la policy blocca solo anonymous, usiamo solo quello
		if (this.canShowAnonymousSurveyOnly) {
			this.survey.sending_mode = "a";
		}

		if (this.sender_email == "") {
			this.sender_email = this.help_mail;
		}
	},
};
</script>

<style>
.b-sidebar-header strong {
  color: white;
}
.card .card-header {
  font-size: 1.5em;
  font-weight: 800;
  border-bottom: 1px solid #aaa !important;
  padding: 14px;
}
.heavy {
  font-weight: 800;
  color: black;
  font-size: 1.5em;
}

.question-edit fieldset {
  border: 1px solid silver !important;
  border-radius: 5px;
  padding: 1em !important;
  margin-bottom: 1em !important;
}
/*.b-sidebar-outer {
    height: 100% !important;
    background: rgba(0,0,0,0.7) !important;
}*/

#forced-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
</style>
