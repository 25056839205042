<template>
    <div class="question-option-editor">
        <div v-if="type != 'array'">
            <div v-for="(option, index) in opts" :key="index">
                <b-input-group size="sm" class="mt-3" :key="index">

					<b-input-group-prepend  v-if="canShowTranslation">
						<b-select size="sm" v-model="lang">
							<b-select-option value="it">it</b-select-option>
							<b-select-option value="en">en</b-select-option>
						</b-select>
					</b-input-group-prepend>

                    <b-form-input v-if="lang=='it'" v-model="opts[index]" v-on:update="emitValue()"></b-form-input>
                    <b-form-input v-else v-model="tt[index]" v-on:update="emitValue()"></b-form-input>

                    <b-input-group-append size="sm">
                        <b-button size="sm" variant="danger" @click="deleteOption(index)"><i class="fa fa-trash"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <b-button size="sm" @click="addOption()">Aggiungi opzione</b-button>
        </div>

        <div v-if="type == 'array'">
            <b-form-group>
                <label>Abilita "Altro"</label>
                <b-form-checkbox class="float-left" v-model="arrOpts.allowOther" label="Abilita 'Altro'"></b-form-checkbox>
            </b-form-group>
            <div v-for="(optionGroup, index) in arrOpts.groups" :key="index">
                <b-input-group prepend="Gruppo" class="mt-3" size="sm">
                    <b-form-input v-model="optionGroup.label"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="danger" @click="deleteArrGroup(index)"><i class="fa fa-trash"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                <hr />
                <div v-for="(option, optIndex) in optionGroup.options" :key="optIndex">
                    <b-input-group size="sm" class="mt-3" :key="optIndex">
                        <b-form-input v-model="optionGroup.options[optIndex]" v-on:update="emitValue()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="danger" @click="deleteArrOption(index, optIndex)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <b-button size="sm" @click="addArrOption(index)">Aggiungi opzione</b-button>
                <hr />
            </div>
            <b-button variant="warning" size="sm" @click="addArrGroup()">Aggiungi gruppo</b-button>
        </div>
    </div>
</template>
<script>
export default {
	name: "question-option-editor",
	props: {
		value: [Number, String, Array, Object],
		type: String,
		canShowTranslation: Boolean,
		trans: Array,
	},
	data() {
		return {
			opts: [], // usato se non array
			tt: [], // usato se non array
			arrOpts: {
				allowOther: false,
				groups: [],
			}, // usato se array
			lang: "it",
		};
	},
	mounted() {
		try {
			const v = { ...this.value };			
			console.log("v->", v);			
			console.log("Trans->",this.trans);
			this.tt =  this.trans;

			if (this.type == "array") {
				this.arrOpts = v;
			} else if (this.type == "single" || this.type == "multiple") {
				this.opts = Object.values(v); //Devo convertire l'oggetto in array
				this.tt = this.trans;			
			}
		} catch (e) {
			// invalid json
			this.opts = [];
			this.arrOpts = {
				allowOther: false,
				groups: [],
			};
		}		
	},
	methods: {
		emitValue() {
			if (this.type == "array") {
				this.$emit("input", this.arrOpts);
			} else if (this.type == "single" || this.type == "multiple") {
				this.$emit("input", this.opts);
				this.$emit("trans", this.tt);
			} else {
				this.$emit("input", "");
			}
		},

		addOption() {
			this.opts.push("");
			this.emitValue();
		},
		deleteOption(index) {
			this.opts.splice(index, 1);
			this.emitValue();
		},
		deleteArrGroup(index) {
			this.arrOpts.groups.splice(index, 1);
			this.emitValue();
		},
		deleteArrOption(groupIndex, index) {
			this.arrOpts.groups[groupIndex].options.splice(index, 1);
			this.emitValue();
		},
		addArrGroup() {
			this.arrOpts.groups.push({
				label: "",
				options: [],
			});
			this.emitValue();
		},
		addArrOption(groupIndex) {
			this.arrOpts.groups[groupIndex].options.push("");
			this.emitValue();
		},
	},
};
</script>

<style>
.question-option-editor .input-group {
    margin: 2px;
    margin-top: 0 !important;
}
</style>
