<template>
    <div>
        <div class="row">
            <div class="col-12">
                <b-button variant="primary" class="float-right" :href="`/pascal/comparisonreport`" target="_blank">Report comparativo</b-button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <b-list-group>
                    <b-list-group-item v-for="c in listComuni" :key="c.id">
                        <b-link :to="`/pascal/edit/${c.id}`">{{ c.name }} </b-link>
                        <b-button class="float-right" varian="secondary" authorize="admin" size="sm" @click.prevent="openMeasures(c.id)">
                            Configura le misure del Comune
                        </b-button>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>

        <b-modal @ok="handleOk()" :title="`Misure del Comune`" id="misure-modal">
            <table class="table table-striped">
                <b-form-checkbox-group id="checkbox-group-2" v-model="selected_measures" name="flavour-2">
                    <tr v-for="m in measures" :key="m.id">
                        <td>
                            <b-checkbox :value="m.id" size="sm">{{ m.name }}</b-checkbox>
                        </td>
                    </tr>
                </b-form-checkbox-group>
            </table>
        </b-modal>
    </div>
</template>
<script>
import UserService from "@/services/user.service";
import measures from "@/data/pascal-measures";
import Util from "@/mixing/util";

export default {
	data() {
		return {
			measures: measures,
			selected_measures: [],
			listComuni: [],
			municipality_id: null,
		};
	},
	mixins: [Util],
	methods: {
		async handleOk() {
			try {
				await UserService.updatePascalMeasures(this.municipality_id, this.selected_measures);
			} catch (err) {
				this.showError(err);
			}
		},
		async openMeasures(municipality_id) {
			this.municipality_id = municipality_id;
			try {
				let res = await UserService.getPascalMeasures(this.municipality_id);
				this.selected_measures = res.data.pascal_measures;
				if (this.selected_measures == null) {
					this.selected_measures = [...Array(measures.length).keys()];
				}
				this.$bvModal.show("misure-modal");
			} catch (err) {
				this.showError(err);
			}
		},
	},
	async created() {
		let tmp_rslt = null;
		try {
			tmp_rslt = await UserService.getMunicipalitiesList();
		} catch (error) {
			this.showError(error);
		}
		this.listComuni = tmp_rslt.data.municipalities;
	},
};
</script>
<style></style>
