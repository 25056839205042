//Pagina Nuda per le tariffe:http://percorsieorari.gtt.to.it/tariffe_extra/
//Pagina Web Tariffe: http://www.gtt.to.it/cms/biglietti-abbonamenti/biglietti/tariffe-extraurbano
//Elenco Paesi: http://percorsieorari.gtt.to.it/tariffe_extra/ajaxPaesi.php?term=Novello
//PDF - Tariffe: http://www.gtt.to.it/cms/risorse/intercomunale/prontuario_tariffe_extr_2019.pdf

const tariffe_gtt = {
	"Urbano Torino - Formula U": {
		tariff_id: "1", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Urbano Torino - Formula U",
		price: 310,
	},
	"Area Extraurbana - 1 Fascia": {
		tariff_id: "1", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 1 Fascia",
		price: 318,
	},
	"Area Extraurbana - 2 Fasce": {
		tariff_id: "2", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 2 Fasce",
		price: 407.5,
	},
	"Area Extraurbana - 3 Fasce": {
		tariff_id: "3", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 3 Fasce",
		price: 460.5,
	},
	"Area Extraurbana - 4 Fasce": {
		tariff_id: "4", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 4 Fasce",
		price: 517.5,
	},
	"Area Extraurbana - 5 Fasce": {
		tariff_id: "5", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 5 Fasce",
		price: 563.5,
	},
	"Area Extraurbana - 6 Fasce": {
		tariff_id: "6", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 6 Fasce",
		price: 598,
	},
	"Area Extraurbana - 7 Fasce": {
		tariff_id: "7", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 7 Fasce",
		price: 673,
	},
	"Area Extraurbana - 8 Fasce": {
		tariff_id: "8", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - 8 Fasce",
		price: 673,
	},
	"Area Extraurbana - U-IVREA": {
		tariff_id: "8", //Questo codice non lo so, metto un numero a caso, ma diverso
		description: "Area Extraurbana - U-IVREA",
		price: 289,
	},
	"Area Integrata - Formula 1": {
		tariff_id: "Formula 1",
		description: "Area Integrata - Formula 1",
		price: 328.5,
	},
	"Area Integrata - Formula 2": {
		tariff_id: "Formula 2",
		description: "Area Integrata - Formula 2",
		price: 369,
	},
	"Area Integrata - Formula 3": {
		tariff_id: "Formula 3",
		description: "Area Integrata - Formula 3",
		price: 522,
	},
	"Area Integrata - Formula 4": {
		tariff_id: "Formula 4",
		description: "Area Integrata - Formula 4",
		price: 639,
	},
	"Area Integrata - Formula 5": {
		tariff_id: "Formula 5",
		description: "Area Integrata - Formula 5",
		price: 733.5,
	},
	"Area Integrata - Formula 6": {
		tariff_id: "Formula 6",
		description: "Area Integrata - Formula 6",
		price: 805.5,
	},
	"Area Integrata - Formula 7": {
		tariff_id: "Formula 7",
		description: "Area Integrata - Formula 7",
		price: 855,
	},
	"Area Integrata - Formula i": {
		tariff_id: "Formula i",
		description: "Area Integrata - Formula i",
		price: 895.5,
	},
	"Area Integrata - Formula 2+1": {
		tariff_id: "Formula 2+1",
		description: "Area Integrata - Formula 2+1",
		price: 441,
	},
	"Area Integrata - Formula 2+2": {
		tariff_id: "Formula 2+2",
		description: "Area Integrata - Formula 2+2",
		price: 517.5,
	},
	"Area Integrata - Formula 2+3": {
		tariff_id: "Formula 2+3",
		description: "Area Integrata - Formula 2+3",
		price: 585,
	},
	"Area Integrata - Formula 2+4": {
		tariff_id: "Formula 2+4",
		description: "Area Integrata - Formula 2+4",
		price: 652.5,
	},
	"Area Integrata - Formula 3+1": {
		tariff_id: "Formula 3+1",
		description: "Area Integrata - Formula 3+1",
		price: 594,
	},
	"Area Integrata - Formula 3+2": {
		tariff_id: "Formula 3+2",
		description: "Area Integrata - Formula 3+2",
		price: 670.5,
	},
	"Area Integrata - Formula 3+3": {
		tariff_id: "Formula 3+3",
		description: "Area Integrata - Formula 3+3",
		price: 738,
	},
	"Area Integrata - Formula 3+4": {
		tariff_id: "Formula 3+4",
		description: "Area Integrata - Formula 3+4",
		price: 805.5,
	},
};

export default tariffe_gtt;
