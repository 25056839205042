<template>
	<b-overlay :show="busy" rounded="sm">
    <div class="page-holder bg-cover d-flex flex-column sticky-footer-wrapper cover-back" id="questionario-fill">
		
        <b-container class="flex-fill" no-title>
			<b-modal id="modal-language"  centered v-model="modalLanguage"   @close="closeWindow" title="Scegli la lingua / Choose your language">
				<div class="text-center">
				<b-button @click="changeLanguage('it')" variant="outline-primary" class="btn-flag"><img :src="`${apiPath}img/it.svg`" height="50" alt="Bandiera Italiana" ><br>ITA</b-button>
				&nbsp;
				<b-button @click="changeLanguage('en')" variant="outline-primary" class="btn-flag"><img :src="`${apiPath}img/gb.svg`" height="50" alt="English Flag"><br>ENG</b-button>
				</div>
				<template #modal-footer="">
					&nbsp;
				</template>
			</b-modal>

            <b-modal id="modal-privacy" v-model="modalShow" size="lg" ok-only :ok-title="frasi.procedo" @close="closeWindow" @ok="handleOk">
                <template #modal-title>
                    <h4>{{ survey.name }}</h4>
                </template>
                <div v-html="frasi.welcome"></div>
            </b-modal>

            <b-alert v-if="survey === false" show variant="warning">
                <h2>Questionario non trovato o non attivo</h2>
                <span v-if="error">{{ String(error) }}</span>
            </b-alert>

            <div v-if="survey && survey.questions && q == null && survey_completed">
                <h2>{{ survey.name }}</h2>
                <b-alert show variant="dark" style="border: 4px dotted black" class="col-md-8">
                    <h2>Grazie!</h2>
                    <p>
                        Hai completato il questionario {{ survey.name }}: <br />
                        il tuo tempo e la tua opinione sono per noi molto importanti.
                    </p>
                    <p>A presto.</p>
					<p style="border: 2px dotted black; font-size:18pt; padding: 3px">Ora puoi chiudere questa finestra.</p>
                </b-alert>
            </div>

            <div v-if="survey && survey.questions && q" id="survey-container">
				<b-card v-if="survey.logo" :img-src="`${serverPath}/${siteDir}/${survey.logo}`" img-alt="survey.logo" img-left class="mt-3 p-2" >
					<b-card-body style="border-left: 1px solid silver; margin-left: 2em; padding-left: 2em">
						<h3>{{survey.name}}</h3>
					</b-card-body>
				</b-card>
                

                <b-card v-if="q" class="col-md-12" :key="q.id" :title="currSectionTitle()">
                    <b-progress :value="progress" :max="getQuestionsNum()"></b-progress>
                </b-card>

                <transition name="fade" mode="out-in">
					<b-nav tabs align="right">	
						<b-card class="col-md-12" v-if="q" :key="q.id">
							<template v-slot:header>
								<h3 class="mb-0">{{ frasi.description }}</h3>
								<b-form-text v-html="frasi.long_description"></b-form-text>
							</template>

							<b-form-group>
								<b-form-input v-if="q.type == 'text'" name="q.id" v-model.lazy="answer[q.id]"></b-form-input>

								<div v-if="q.options !== null && q.type == 'single' && q.options.length <= 8">
									<b-form-radio v-for="(opt, key) in q.options" :key="key" :value="opt" v-model.lazy="answer[q.id]">{{ frasi.options[key] }}</b-form-radio>
								</div>

								<div v-if="q.options !== null && q.type == 'single' && q.options.length > 8">
									<b-form-select v-model="answer[q.id]"> 
										<b-form-select-option :value="null">---</b-form-select-option>
										<b-form-select-option v-for="(opt, key) in q.options" :key="key" :value="opt">{{ frasi.options[key] }}</b-form-select-option>
									</b-form-select>
								</div>

								<b-form-checkbox-group stacked v-if="q.type == 'multiple'" v-model.lazy="answer[q.id]">
									<b-form-checkbox v-for="(opt, index) in q.options" :key="index" :value="opt" inline>{{ frasi.options[index] }}</b-form-checkbox>
								</b-form-checkbox-group>

								<div v-if="q.type == 'array'">
									<b-form-group stacked v-for="(group, index) in q.options.groups" :key="index" :label="group.label">
										<b-form-select :options="group.options" v-model="answer[q.id][index]"> </b-form-select>
									</b-form-group>
									<b-form-group v-if="q.options.allowOther">
										<label>Altro</label>
										<b-form-input v-model="answer[q.id]['altro']"></b-form-input>
									</b-form-group>
								</div>

								<div v-if="q.type == 'map'">
									<map-question :survey_id="survey_id" 
									v-on:response-changed="answer[q.id] = $event"
									:answer= "answer"
									@back="back()"
									@save="saveContinue()"> </map-question>
								</div>
								<span class="text-danger text-small" v-if="q.compulsory_answer">{{frasi.obbligatoria}}</span>
							</b-form-group>

							<hr />

							<b-button v-if="q.id !== this.survey.questions[0].id && q.type != 'map'" variant="primary" class="float-left" @click.prevent="back()">
								{{frasi.indietro}}
								<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
							</b-button>

							<b-spinner label="Spinning" class="float-right" v-if="saving"></b-spinner>

							<b-button :disabled="saving" variant="success" class="float-right" @click.prevent="saveContinue()"  v-if="q.type != 'map'" >
								{{frasi.salva_continua}}
								<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
							</b-button>
						</b-card>											  
					</b-nav>
				</transition>

				<b-card v-if="survey.survey_delivery_config.mail_footer">
					<b-card-text> 
						<small class="text-muted float-right" v-html="survey.survey_delivery_config.mail_footer">							
						</small>
					</b-card-text>					
				</b-card>
            </div>			
        	</b-container>			
        <content-footer></content-footer>
    </div>
	</b-overlay>
</template>

<script>
import UserService from "@/services/user.service";
import _ from "lodash";
import QuestionService from "@/services/question.service";
import Util from "@/mixing/util";
import ContentFooter from "~theme/ContentFooter.vue";
import { AST_EmptyStatement } from "terser";

//TODO: COMPLETE TRANSLATION OF UX
const frasi = {
	it: {
		salva_continua: "Salva e Continua",
		procedo: "Procedo con la Compilazione",
		indietro: "Indietro",
		obbligatoria: "Questa domanda è obbligatoria",
	},

	en:  {
		salva_continua: "Save and Continue",
		procedo: "Proceed to the Survey",
		indietro: "Back",
		obbligatoria: "This question is compulsory",
	}
};

export default {
	name: "QuestionariFill",
	mixins: [Util],
	components: {
		MapQuestion: () => import("@/components/MapQuestion.vue"),
		ContentFooter,
	},
	data() {
		return {
			survey: [],
			answer: [],
			sections: [],
			participant_id: null,
			survey_id: null,
			error: null,
			survey_completed: false,
			q: null,
			isTest: false,
			saving: false,
			modalShow: false,
			modalLanguage: false,
			welcome: null,
			progress: 0,
			language: "", //"en" se voglio il default in inglese
			step:0,
			sectionIds: [],
			frasi: frasi["it"],
			busy: false,
			serverPath: process.env.VUE_APP_ROOT,
		};
	},
	methods: {
		async changeLanguage(lang){
			this.language = lang;
			this.loadQuestions();	
			this.getTranslatedSurvey(this.survey.id);
			this.modalLanguage = false;
			this.modalShow = !(this.$cookies.get("consenso_questionario") == "true");
			let opt = this.frasi.options;
			this.frasi = frasi[this.language];
			this.frasi.options = opt;					
		},

		// anche se sono metodi "grafici" che potrebbero essere implementati come "computed" l'aggiornamento
		// non viene scatenato correttamente con le domande condizionali, li rendo metodi a tutti gli effetti
		currSectionTitle: function () {
			if (this.survey && this.survey.questions && this.q) {
				const currSectionId = this.q._joinData.section_id;
				const currSection = this.sections.find((s) => s.id == currSectionId);
				return currSection ? `${currSection["name"]}` : "";
			}
			return "";
		},
		getQuestionsNum: function () {
			// non c'è un modo semplice per ottenere il numero reale di domande (per via di quelle condizionali):
			// mi limito a visualizzare sempre il numero di tutte, "saltando" opporturnamente sulle condizionali
			return this.survey.questions.length;
		},
		updateProgress() {
			if (this.q == null) {
				this.progress = this.survey.questions.length;
			} else {
				this.progress = this.survey.questions.indexOf(this.q);
			}
		},
		async back() {
			this.q = this.getPreviousQuestion(this.q);
			await this.getTranslatedQuestion(this.q.id);
			this.updateProgress();
		},
		closeWindow(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			alert("Se non vuoi partecipare, chiudi semplicemente la finestra del browser");
		},
		handleOk() {
			//Salvo il cookie con il consenso
			this.$cookies.set("consenso_questionario", true);
		},
		reset() {
			this.q = this.survey.questions[0];
			this.updateProgress();
		},
		async saveContinue() {
			this.saving = true;
			//console.log("answer",this.answer);
			localStorage.setItem(`answer.${this.q.id}`, JSON.stringify(this.answer));
			try {
				if (!this.checkForm()) {
					this.saving = false;
					return;
				}

				if (!this.isTest) {
										
					const res = await UserService.updateAnswers(this.answer, this.survey_id, this.participant_id);
					
					if (res.data.errorMsg == false) {
						this.$bvToast.toast("La risposta è stata salvata correttamente", {
							variant: "success",
							title: "Salvataggio Ok",
							autoHideDelay: 2000,
							appendToast: true,
						});
					} else {
						console.log(res);
						throw new Error(res.data.errorMsg);
					}
				}

				this.q = this.getNextQuestion(this.q);
				if (this.q !== null){
					await this.getTranslatedQuestion(this.q.id);
				}
				
				this.updateProgress();
				if (this.q == null) {
					// questionario completato
					if (!this.isTest) {
						await UserService.surveyCompleted(this.survey.id, this.participant_id);
						this.$cookies.remove("participant_id");
						this.$cookies.remove("consenso_questionario");
					}
					this.survey_completed = true;
				}

				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} catch (e) {
				console.log(e);
				this.$bvToast.toast(String(e), {
					variant: "danger",
					title: "Errore",
					autoHideDelay: 2000,
					appendToast: true,
				});
			} finally {
				this.saving = false;
			}
		},
		checkForm() {
			//console.log("answer", this.answer[this.q.id]);
			if ( (	this.answer[this.q.id] == null ||
					this.answer[this.q.id].length == 0 || 
					Object.keys(this.answer[this.q.id]).length === 0  ) && 
					this.q.compulsory_answer) {
				alert(this.frasi.obbligatoria);
				return false;
			}
			return true;
		},
		// specifica se una domanda può essere visualizzata oppure no
		canDisplay(question) {
			//console.log("Can displayQuestion", question);
			let currQuestionIdx = this.survey.questions.indexOf(question);
			if (question.conditions) {
				//console.log("Question.conditions");
				// domanda condizionale, verifica se deve essere visualizzata o saltata
				let display = false; // init a false
				let parentQuestion = this.survey.questions.find((q) => question.conditions.question == q.id);
				if (!parentQuestion) {
					// la domanda a cui si riferisce non esiste (non dovrebbe mai succedere)
					display = false;
				} else if (this.survey.questions.indexOf(parentQuestion) > currQuestionIdx) {
					display = false; // la domanda da cui dipende è successiva (non ha senso), non la visualizzo
				} else {
					let parentAnswer = this.answer[parentQuestion.id];
					// visualizza la domanda condizionale se esiste almeno un match tra risposta data e condizioni
					if (parentQuestion.type == "array") {
						if (!parentAnswer) {
							// non ho risposto
							parentAnswer = {};
						}
						display =
                            Object.keys(parentAnswer).map((k) => {
                            	if (isNaN(k)) return false;
                            		if (question.conditions.value[k] === undefined) return false;
                            		// normalizza entrambi i valori ad array (in modo da gestire qualunque caso, ad es. se non ho risposto alla domanda da cui dipende)
                            		let v1 = Array.isArray(parentAnswer[k]) ? parentAnswer[k] : [parentAnswer[k]];
                            		//console.log("v1", v1);
                            		let v2 = Array.isArray(question.conditions.value[k]) ? question.conditions.value[k] : [question.conditions.value[k]];
                            		//console.log("v2", v2);
                            		return _.intersection(_.flattenDeep(v1), _.flattenDeep(v2)).length > 0;
                            	})
                            	.filter((v) => v == true).length > 0;
					} else {
						//console.log("Single o multiple");
						// multiple o single
						if (!parentAnswer) {
							// non ho risposto
							parentAnswer = [];
						}
						// normalizza entrambi i valori ad array (in modo da gestire qualunque caso, ad es. se non ho risposto alla domanda da cui dipende)
						let v1 = Array.isArray(parentAnswer) ? parentAnswer : [parentAnswer];
						let v2 = Array.isArray(question.conditions.value) ? question.conditions.value : [question.conditions.value];
						display = _.intersection(_.flattenDeep(v1), _.flattenDeep(v2)).length > 0;
					}
				}
				return display;
			}
			return true;
		},
		// restituisce nell'ordine la prossima domanda da visualizzare
		getNextQuestion(question) {
			let currQuestionIdx = this.survey.questions.indexOf(question);
			if (currQuestionIdx == this.survey.questions.length - 1) {
				return null; // non ci sono altre domande nell'ordine
			}
			let nextQuestion = this.survey.questions[currQuestionIdx + 1];
			if (!this.canDisplay(nextQuestion)) {
				return this.getNextQuestion(nextQuestion);
			}
			return nextQuestion;
		},
		// restituisce nell'ordine la precedente domanda da visualizzare
		getPreviousQuestion(question) {
			let currQuestionIdx = this.survey.questions.indexOf(question);
			if (currQuestionIdx == 0) {
				return null; // non ci sono altre domande nell'ordine
			}
			let prevQuestion = this.survey.questions[currQuestionIdx - 1];
			if (!this.canDisplay(prevQuestion)) {
				return this.getPreviousQuestion(prevQuestion);
			}
			return prevQuestion;
		},
		async getTranslatedSurvey(sid) {
			if (this.language == "en") {
				let t = await UserService.getSurveyTranslations(sid);				
				//Se non c'è la traduzione uso la stessa della lingua originale
				if (t.data === undefined || t.data.t.length == 0 || t.data.t.en.welcome == undefined || t.data.t.en.welcome == ""){
					this.frasi.welcome = this.survey.welcome;
				} else {
					this.frasi.welcome = t.data.t.en.welcome;
				}
				return;
			} 
			this.frasi.welcome = this.survey.welcome;
		},
		//Carica le traduzioni delle opzioni di una domanda
		async getTranslatedQuestion(qid){
			if (this.language == "en") {
				let t = await UserService.getQuestionTranslations(qid);
				//console.log("T",t.data.t.en.options);
				//Se non c'è la traduzione uso la stessa della lingua originale
				if (t.data === undefined || t.data.t.length == 0 || t.data.t.en.options == undefined){
					this.frasi.options = this.q.options;
				} else {
					this.frasi.options = t.data.t.en.options;		
				}
				if (t.data === undefined || t.data.t.length == 0 || t.data.t.en.description == undefined || t.data.t.en.description == ""){
					this.frasi.description = this.q.description;
				} else {
					this.frasi.description = t.data.t.en.description;
				}
				if (t.data === undefined || t.data.t.length == 0 || t.data.t.en.long_description == undefined || t.data.t.en.long_description == ""){
					this.frasi.long_description = this.q.long_description;
				} else {
					this.frasi.long_description = t.data.t.en.long_description;
				}				
				return;
			} 
			this.frasi.options = this.q.options;
			this.frasi.long_description = this.q.long_description;
			this.frasi.description = this.q.description;
		},
		//Questa funzione ricarica le domande, importante sopratutto nel caso di cambio lingua
		async loadQuestions(){
			let q = await UserService.getQuestions(this.survey_id);
			//console.log("survey", q.data.survey);
			this.survey = q.data.survey;

			//Se il questionario non è traducibile, lo metto a false
			//console.log("T", this.survey.show_translation);
			if (this.survey.show_translation != "en"){
				this.canShowTranslation = false;
			}

			// inizia le domande (importantissimo!)
			QuestionService.initQuestions(this.survey.questions, false);
			// rimuovi tutte le domande nascoste
			this.survey.questions = this.survey.questions.filter((q) => q._joinData && !q._joinData.hidden);
			// rimuovi tutte le domande che non hanno un tipo
			this.survey.questions = this.survey.questions.filter((q) => q.type);
			// rimuovi tutte le domande di tipo single, multiple, array con opzioni vuote
			this.survey.questions = this.survey.questions.filter((q) => {
				if ((q.type == "single" || q.type == "multiple" || q.type == "array") && !q.options) {
					return false;
				}
				return true;
			});
			// se qualche domanda non ha section_id prendila dalla parent question
			this.survey.questions.forEach((q) => {
				if (!q._joinData.section_id) {
					q._joinData.section_id = q.section_id;
				}
			});
			// rimuovi tutte le domande che non hanno una sezione collegata
			this.survey.questions = this.survey.questions.filter((q) => q._joinData && q._joinData.section_id && this.sectionIds.indexOf(q._joinData.section_id) != -1);

			// ordina le domande per section id e per peso all'interno della section
			this.survey.questions.sort((q1, q2) => {
				let w1 = q1._joinData && q1._joinData.weight ? q1._joinData.weight : 0;
				let w2 = q2._joinData && q2._joinData.weight ? q2._joinData.weight : 0;
				if (q1._joinData.section_id != q2._joinData.section_id) {
					return q1._joinData.section_id - q2._joinData.section_id;
				} else {
					return w1 - w2;
				}
			});

			// deserilizza le condizioni e rimuovi le condizioni non valide
			this.survey.questions.forEach((q) => {
				if (q.conditions) {
					q.conditions = q.conditions.display == "conditionally" ? q.conditions : false;
				} else {
					q.conditions = false;
				}
			});

			//massimoi 11/9/2020 - devo inizializzare l'array delle risposte
			//perchè nel v-model uso un oggetto a due livelli e non riesce a creare al volo
			//il secondo livello (perchè il primo è undefined)
			this.survey.questions.forEach((question) => {
				if (question.type == "array") {
					this.answer[question.id] = {};
				} else {
					this.answer[question.id] = undefined;
				}
			});

			// il questionario comincia dalla prima domanda nell'ordine che NON sia condizionale (se per errore
			// ci sono domande condizionali all'inizio scartale)
			while (this.survey.questions[0].conditions) {
				this.survey.questions.shift();
			}

			//Carico le traduzioni per la prima domanda
			this.q = this.survey.questions[0];
			await this.getTranslatedQuestion(this.q.id);

			//Imposto il testo per il modale con il disclaimer iniziale
			if (this.survey.welcome !== null) {
				this.frasi.welcome = this.survey.welcome;
			}

			//Leggo le informazioni generali sul questionario
			//Mostro il consenso se sono in test o se non l'ho ancora fatto
			if (!this.$cookies.isKey("consenso_questionario") || this.isTest) {
				this.$cookies.set("consenso_questionario", false);

			}

			this.q = this.survey.questions[0];
			return q;
		},
	},
	async created() {
		this.busy = true;
		this.survey_id = this.$route.params.survey_id;
		this.participant_id = this.$route.params.participant_id;
		this.$cookies.config("1d");
		
		let surv_default = await import("@/theme/" + process.env.VUE_APP_THEME + "/survey-defaults");
		this.frasi.welcome = "";

		try {
			if (this.participant_id === "anon") {
				//Tolgo il cookie all'anonimo per permettere di gestire postazioni condivise
				this.$cookies.remove("participant_id");
				this.$cookies.remove("consenso_questionario");

				if (this.$cookies.isKey("participant_id")) {
					//Se è anonimo memorizzo lo user in modo che l'utente possa continuare la compilazione
					this.participant_id = this.$cookies.get("participant_id");
				} else {
					const res = await UserService.addAnonParticipant(this.survey_id);
					if (res.data.errorMsg != false) {
						throw new Error(res.data.errorMsg);
					}
					this.participant_id = res.data.participant_id;
					this.$cookies.set("participant_id", this.participant_id);
				}
			} else if (this.participant_id === "test") {
				this.isTest = true;
			}
			
			let z = await UserService.getSections();
			this.sections = z.data.sections;

			this.sectionIds = this.sections.map((s) => s.id);

			let q = await this.loadQuestions();

			//Controllo la validità delle date
			const opening_date = new Date(this.survey.opening_date); 
			const closing_date = new Date(this.survey.closing_date); 
			const current_date = new Date(); 
			if (opening_date > current_date || closing_date < current_date )
			{
				//Messaggio di errore
				this.$bvModal.msgBoxOk("Il questionario richiesto è chiuso. Puoi contattare il tuo mobility manager per chiedere informazioni.",{
					title: "Questionario chiuso",
					okVariant: "danger"
				}).then( () => {
					window.close();
				});
			}
			
			if (this.canShowTranslation){
				this.modalLanguage = true; 
			} else {
				this.changeLanguage("it");
			}
			this.busy = false;
		} catch (e) {
			// todo: perchè non riesco a vedere l'effettivo messaggio di errore di cake?
			this.error = e;
			this.survey = false;
			this.busy = false;
		}
	}
};
</script>

<style scoped>
.b-sidebar-header strong {
    color: white;
}
.card-header h2 {
    font-weight: 900 !important;
    font-size: 2em !important;
    line-height: 1.1 !important;
    margin-top: 0;
    margin-bottom: 20px;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.btn-flag {
	width: 200px;
}
 

.card-img-left {
   object-fit: contain;
   max-width: 200px;
   max-height: 200px;
   width: auto;
   height: auto;
}

</style>
