class QuestionService {
	initQuestions(questions) {
		// se per errore una o più domande non ha section_id impostato, prendilo dalla section della domanda
		// parent
		questions.forEach((q) => {
			if (q._joinData && !q._joinData.section_id) {
				q._joinData.section_id = q.section_id;
			}
			if (typeof q.en == undefined || q.en == null){
				q.en = {
					description: "",
					long_description: "",
				};
			}
		});
		
	}
}

export default new QuestionService();
