<template>
    <div>
        <b-tabs content-class="mt-3" @input="onTabChange()">
            <b-tab title="Tabella" active>
                <b-table small selectable striped :items="result_from_backend" :fields="fields" :busy="busy">
                    <template #cell(Riduzione Km Gg Auto)="data">
                        {{ data.value | round2 }}
                    </template>
                    <template #cell(C)="data">
                        {{ data.value | round2 }}
                    </template>
                    <template #cell(CO)="data">
                        {{ data.value | round2 }}
                    </template>
                    <template #cell(CO2)="data">
                        {{ data.value | round2 }}
                    </template>
                    <template #cell(NOx)="data">
                        {{ data.value | round2 }}
                    </template>
                    <template #cell(PM10)="data">
                        {{ data.value | round2 }}
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Istogramma" style="height: 1050px">
                <GChart type="ColumnChart" :resizeDebounce="0" :data="emissionsChartData" :options="chartOptions" />
            </b-tab>
            <!-- <b-tab title="check">{{ checker }}</b-tab> -->
        </b-tabs>
    </div>
</template>
<script>
import Util from "@/mixing/util";
//import { ChartCard } from "@/components/index";
import emissionsConst from "@/data/emissions-const";
import Filters from "@/mixing/filters";

import { GChart } from "vue-google-charts";

import UserService from "@/services/user.service";

export default {
	name: "Impact",

	mixins: [Filters, Util],
	props: {
		measureId: Number,
		officeId: String,
		// rowIndex: Number,
		measure: Object,
		monitoring: Object,
		busy: Boolean,
	},
	components: {
		//ChartCard,
		GChart,
	},
	data() {
		return {
			checker: null,
			ec: emissionsConst,
			chart: null,
			monitorings: null,
			fields: ["name", "riduzione_km_gg_auto", "C", "CO", "CO2", "NOx", "PM10"],
			emissionsChartData: [["name"], ["riduzione_km_gg_auto"], ["C"], ["CO"], ["CO2"], ["NOx"], ["PM10"]],
			chartOptions: {
				title: null,
				titleTextStyle: {
					bold: false,
				},
				height: 700,
				width: 1300,
				vAxis: {
					title: "",
				},
				legend: { position: "right" },
			},
			result_from_backend: null,
		};
	},
	methods: {
		onTabChange() {
			this.$forceUpdate();
		},
		// calculateIndicator(serie) {
		//   if (serie == undefined) {
		//     return {};
		//   }
		//   let A = this.measure.indicator(serie);
		//   if (A == null) {
		//     return {};
		//   } else {
		//     return A.getOutput();
		//   }
		// },
	},
	async mounted() {
		let chartData = [["name", "riduzione_km_gg_auto", "C", "CO", "CO2", "NOx", "PM10"]];
		let row = 0;
		this.monitorings = this.monitoring;

		if (this.monitorings != undefined || this.monitorings != null) {
			// this.monitoring.series.forEach((element) => {
			//   this.monitorings.out[row] = this.calculateIndicator(element);
			//   this.monitorings.out[row].name = element.name;
			//   row++;
			// });

			try {
				let rslt = await UserService.getPascalMeasureImpacts(this.officeId, this.measureId);
				this.result_from_backend = rslt.data.impacts;

				if (this.result_from_backend != undefined) {
					this.result_from_backend.forEach((element) => {
						let tmp = [];
						delete element.monitoring;
						delete element.officeName;
						for (let prop in element) {
							if (prop == "name") {
								tmp.unshift(element[prop]);
							}
							if (prop != "name") {
								tmp.push(element[prop]);
							}
						}
						if (tmp.length > 0) {
							chartData.push(tmp);
						}
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				row = 0;
				chartData.forEach((element) => {
					if (row === 1) {
						for (let num = 0; num < element.length; num++) {
							this.emissionsChartData[num].push(element[num]);
						}
					}
					row = 1;
				});
			}
		}
	},
};
</script>
