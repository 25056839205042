<template>
    <div v-if="office.id">
        <h3>{{ company.name }} - {{ office.name }}</h3>
        <h2>
            {{ survey_type === "sede" ? `Questionario Sede` : `Questionario Mobility Label` }}
        </h2>
        <hr />
        <component v-if="survey_form_component" :is="survey_form_component" :form="form"></component>
        <b-button variant="primary" @click="submit()">Salva</b-button>
    </div>
</template>

<script>
import UserService from "@/services/user.service";
import Util from "@/mixing/util";

export default {
	name: "OfficeSurveyWidget",
	mixins: [Util],
	components: {
		"questionario-generale-azienda": () => import("@/components/QuestionarioAzienda/Generale/Azienda.vue"),
		"questionario-generale-scuola": () => import("@/components/QuestionarioAzienda/Generale/Scuola.vue"),
		"questionario-sede-azienda": () => import("@/components/QuestionarioAzienda/Sede/Azienda.vue"),
		"questionario-sede-scuola": () => import("@/components/QuestionarioAzienda/Sede/Scuola.vue"),
		"questionario-label-azienda": () => import("@/components/QuestionarioAzienda/Label/Label.vue"),
		"questionario-label-scuola": () => import("@/components/QuestionarioAzienda/Label/Label.vue"),
	},
	props: {
		survey_type: String,
		office_id: String,
		redirect_to: String,
	},
	data() {
		return {
			form: {},
			survey_form_component: null,
			office: {},
			company: {},
		};
	},
	async created() {
		try {
			this.office = (await UserService.getOffice(this.office_id)).data.office;
			this.company = (await UserService.getCompany(this.office.company_id)).data.company;
			const res = UserService.getCompanySurveyForm(
				this.survey_type,
				(await UserService.getCompanyTypes()).data.company_types,
				this.company.type,
				this.survey_type == "sede" ? this.office.survey : this.office.label_survey
			);
			this.survey_form_component = res.component;
			this.form = res.formData;
			// prefill geo info from office
			if (this.survey_type === "sede") {
				this.form = {
					...this.form,
					ul_comune: this.form.ul_comune ? this.form.ul_comune : this.office.city,
					ul_cap: this.form.ul_cap ? this.form.ul_cap : this.office.cap,
					ul_indirizzo: this.form.ul_indirizzo ? this.form.ul_indirizzo : this.office.address,
				};
			} else {
				this.form = {
					...this.form,
					company_name: this.form.company_name ? this.form.company_name : this.company.name,
					survey_date: Date.now(),
				};
			}
		} catch (e) {
			console.log(e);
			this.$bvToast.toast(e.message, {
				title: "Errore",
				autoHideDelay: 2000,
				appendToast: true,
				variant: "danger",
			});
		}
	},
	methods: {
		async submit() {
			try {
				if (this.survey_type == "sede") {
					this.office.survey = this.form;
				} else {
					this.office.label_survey = this.form;
				}
				await UserService.editOffice(this.office, this.office_id);
				this.$bvToast.toast("Salvataggio avvenuto correttamente", {
					title: "Salvataggio ok",
					autoHideDelay: 2000,
					appendToast: true,
				});
				if (this.redirect_to) {
					setTimeout(() => {
						// set a timeout to let the toast appear before redirecting
						this.$router.push(this.redirect_to);
					}, 500);
				}
			} catch (e) {
				this.$bvToast.toast(e.message, {
					title: "Errore",
					autoHideDelay: 2000,
					appendToast: true,
					variant: "danger",
				});
			}
		},
	},
};
</script>
