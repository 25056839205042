<template>
    <div class="label" :style="`padding-bottom: ${this.pb}px;`" id="labelRef" ref="labelRef">
        <div id="emptySurvey" :style="`display: ${this.showEmptyLabel}`">
            <h1>Il questionario per questa sede non è  ancora stato compilato.</h1>
        </div>

		<div id="main-container" :style="`display: ${this.showLabel}`">
		<div id="cell_r0_c0">
			<img src="/images/MobilityLabel_1.png" width="21" border="0" height="114" alt="" />
		</div>
		<div id="cell_r0_c1">
			<img src="/images/MobilityLabel_2.png" width="478" border="0" height="21" alt="" />
		</div>
		<div id="cell_r0_c2">
			<img src="/images/MobilityLabel_3.png" width="23" border="0" height="114" alt="" />
		</div>
		<div id="cell_r1_c0"><a href="https:/moma.biz"><img src="/images/momabiz-logo.png" width="140" /></a></div>
		<div id="cell_r1_c1"><img src="/images/MobilityLabel_5.png" width="4" border="0" height="93" alt="" /></div>
		<div id="cell_r1_c2" style="background-color: green; padding: 10px">
			<span style="font-size: 18pt; font-weight: bold; color:white;" > Mobility Label<br><small> for Business and Industrial Zones</small></span>

		</div>
		<div id="cell_r2_c0">
			<img src="/images/MobilityLabel_7.png" width="21" border="0" height="91" alt="" />
		</div>
		<div id="cell_r2_c1" class="bkgd_white">
			<h4>
			{{ company_name }}<br />
			<span class="small"> {{ office_name }}</span>
			</h4>
		</div>
		<div id="cell_r2_c2">
			<img src="/images/MobilityLabel_9.png" width="22" border="0" height="91" alt="" />
		</div>
		<div id="cell_r2_c3">
			<img src="/images/MobilityLabel_10.png" width="1" border="0" height="91" alt="" />
		</div>
		<div id="cell_r3_c0">
			<img src="/images/MobilityLabel_11.png" width="21" border="0" height="456" alt="" />
		</div>
		<div id="cell_r3_c1"><img src="/images/scale.png" width="333" border="0" height="456" alt="" /></div>
		<div id="cell_r3_c2" :style="'margin-top:' + vert + 'px;'">
			<div style="line-height: 40px">{{ label_result.class_total }}</div>
		</div>
		<div id="cell_r3_c3">
			<img src="/images/MobilityLabel_14.png" width="23" border="0" height="456" alt="" />
		</div>
		<div id="cell_r4_c0">
			<img src="/images/MobilityLabel_15.png" width="21" border="0" height="265" alt="" />
		</div>
		<div id="cell_r4_c1">
			<div class="icone">
			<div class="row">
				<img :src="`/images/icons/Icons_EAP-box1-${this.label_result.class1}.png`" :title="`Mobilità ciclabile: Class ${this.label_result.class1}`" />
				<img :src="`/images/icons/Icons_EAP-box2-${this.label_result.class2}.png`" :title="`Mobilità pedonale: Class ${this.label_result.class2}`" />
				<img :src="`/images/icons/Icons_EAP-box3-${this.label_result.class3}.png`" :title="`Trasporto pubblico: Class ${this.label_result.class3}`" />
				<img :src="`/images/icons/Icons_EAP-box4-${this.label_result.class4}.png`" :title="`Car pooling: Class ${this.label_result.class4}`" />
			</div>
			<div class="row">
				<img :src="`/images/icons/Icons_EAP-box5-${this.label_result.class5}.png`" :title="`Car sharing: Class ${this.label_result.class5}`" />
				<img :src="`/images/icons/Icons_EAP-box6-${this.label_result.class6}.png`" :title="`Auto privata: Class ${this.label_result.class6}`" />
				<img
				:src="`/images/icons/Icons_EAP-box7-${this.label_result.class7}.png`"
				:title="`Mobilità per persone con difficoltà motorie o disabilità: Class ${this.label_result.class7}`"/>
				<img :src="`/images/icons/Icons_EAP-box8-${this.label_result.class8}.png`" :title="`Ulteriori misure di mobilità: Class ${this.label_result.class8}`" />
			</div>
			</div>
		</div>
		<div id="cell_r4_c2">
			<img src="/images/MobilityLabel_17.png" width="23" border="0" height="265" alt="" />
		</div>
		<div id="cell_r5_c0">
			<img src="/images/MobilityLabel_18.png" width="21" border="0" height="166" alt="" />
		</div>
		<div id="cell_r5_c1">
			<div class="issue-date"><b>Data:</b> {{ curDate }}</div>
					<a href="https:/ec.europa.eu/energy/intelligent/" target="_blank">
					<img align="center" alt="Intelligent Energy Europe" height="50" hspace="5" src="/images/iee_logo_supportedby_72.jpg" width="252"/>
				</a>
				<br>
				<b>Questa è un'etichetta di autovalutazione.</b>
				<p class="small">La sola responsabilità per il contenuto di questa etichetta è degli autori.
					Non rispecchia le opinioni dell'Unione Europea. La Commissione Europea non è responsabile per 
					gli usi che possono essere fatti dei contenuti di questa pagina.
				</p>

		</div>
		<div id="cell_r5_c2">
			<img src="/images/MobilityLabel_20.png" width="23" border="0" height="166" alt="" />
		</div>
		<div id="cell_r6_c0">
			<img src="/images/MobilityLabel_21.png" width="478" border="0" height="13" alt="" />
		</div>
			
		</div>
		
		<div class="boxed">
			<div class="row" style="font-weight: bold">
				<div class="col-md-12">
				
			Azienda: {{ company_name }} - Sede: {{ office_name }} - Classe: {{ label_result.class_total }} - Punteggio: {{ label_result.total }}
				</div>
			</div>
			<div class="row"><div class="col-md-12">Box 1 - Mobilità ciclabile: Classe {{ label_result.class1 }} {{label_result.box1}}</div></div>
			<div class="row"><div class="col-md-12">Box 2 - Mobilità pedonale: Classe {{ label_result.class2 }} {{label_result.box2}}</div></div>
			<div class="row"><div class="col-md-12">Box 3 - Trasporto pubblico: Classe {{ label_result.class3 }} {{label_result.box3}}</div></div>
			<div class="row"><div class="col-md-12">Box 4 – Car pooling: Classe {{ label_result.class4 }} {{label_result.box4}}</div></div>
			<div class="row"><div class="col-md-12">Box 5 – Sharing mobility: Classe {{ label_result.class5 }} {{label_result.box5}}</div></div>
			<div class="row"><div class="col-md-12">Box 6 - Auto privata: Classe {{ label_result.class6 }} {{label_result.box6}}</div></div>
			<div class="row"><div class="col-md-12">Box 7 - Mobilità per persone con difficoltà motorie o disabilità : Classe {{ label_result.class7 }} {{label_result.box7}}</div></div>
			<div class="row"><div class="col-md-12">Box 8 - Ulteriori misure di mobilità: Classe {{ label_result.class8 }} {{label_result.box8}}</div></div>
		</div>
		
		<hr>
		
		<div class="boxed">
			<p>La prima versione della Mobility Label è stata sviluppata nell'ambito del progetto Europeo MoMa.Biz, finanziato dalla linea Intelligent Energy Europe
					con lo scopo di valutare l'accessibilità delle Zone Industriali e dei Centri Uffici in Europa.</p>
		</div>
	</div>
</template>

<script>
//call get to API APP then paginate result
import Util from "@/mixing/util";
import UserService from "@/services/user.service";

export default {
	name: "OfficesLabel",
	mixins: [Util],
	data() {
		return {
			pb: "0",
			curDate: "",
			office_id: 0,
			label_input: {},
			company_name: "",
			office_name: "",
			label_result: {
				box1: 0,
				box2: 0,
				box3: 0,
				box4: 0,
				box5: 0,
				box6: 0,
				box7: 0,
				box8: 0,
				class1: "",
				class2: "",
				class3: "",
				class4: "",
				class5: "",
				class6: "",
				class7: "",
				class8: "",
				total: 0,
				class_total: "",
			},
			showLabel: "none",
			showEmptyLabel: "none",
		};
	},
	computed: {
		vert: function () {
			let array = {
				"A+++": 20,
				"A++": 65,
				"A+": 105,
				A: 150,
				B: 195,
				C: 240,
				D: 280,
				E: 320,
				F: 365,
				G: 407,
			};
			return array[this.label_result.class_total];
			// return array["B"];
		},
	},
	methods: {
		calculateBackground(boxClass) {
			let colors = {
				"A+++": "#23a650",
				"A++": "#50b848",
				"A+": "#bed731",
				A: "#fced22",
				B: "#f9b817",
				C: "#f37122",
				D: "#ec2127",
				E: "#ec2127",
				F: "#ec2127",
				G: "#ec2127",
			};
			return colors[boxClass];
		},
		retriveOfficeLabelData(office_id) {
			UserService.getLabel(office_id)
				.then((response) => {
					let result = response.data.labelSurvey.label_survey;
					//console.log("result: " + result);
					this.label_input = response.data.labelSurvey.label_survey;
					if (this.label_input != undefined || this.label_input != null) {
						this.showLabel = "flex";
						this.company_name = this.label_input.company_name;
						this.calculateLabel();
						this.office_name = this.label_input.office_name;
					} else {
						this.pb = 0;
						this.showEmptyLabel = "flex";
					}
				})
				.catch((error) => {
					this.showError(error);
					this.showEmptyLabel = "flex";
					this.pb = 0;
				});
		},
		getDate() {
			let date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
			let formatted_date = date.split("/").reverse().join("/");
			return formatted_date;
		},
		calculateLabel() {
			this.label_result.box1 =
                parseInt(this.label_input.box1.accessible) * 26.3 +
                parseInt(this.label_input.box1.theft_prevention) * 14.1 +
                parseInt(this.label_input.box1.arrival_bike) * 11.7 +
                parseInt(this.label_input.box1.internal_bikes) * 4.7 +
                parseInt(this.label_input.box1.pool_bikes) * 11.6 +
                parseInt(this.label_input.box1.cycling_business_trips) * 6.0 +	
                parseInt(this.label_input.box1.internal_cycling) * 10.0 +
                parseInt(this.label_input.box1.cycling_infrast) * 15.6;

			this.label_result.box2 = 
			parseInt(this.label_input.box2.azessible) * 34.5 + 
			parseInt(this.label_input.box2.arrival_walk) * 38 + 
			parseInt(this.label_input.box2.infrastructure_walk) * 27.5;

			this.label_result.box3 =
                parseInt(this.label_input.box3.bus_accessible) * 34 +
                parseInt(this.label_input.box3.bus_info) * 12 +
                parseInt(this.label_input.box3.co_financing) * 14 +
                parseInt(this.label_input.box3.bus_facilities) * 4 +
                parseInt(this.label_input.box3.lobby) * 8 +
                parseInt(this.label_input.box3.bus_infrastructure) * 2 +
                parseInt(this.label_input.box3.shuttle_bus) * 23 +
                parseInt(this.label_input.box3.vanpooling) * 3;

			this.label_result.box4 = 
				parseInt(this.label_input.box4.matching_service) * 33.4 + 
				parseInt(this.label_input.box4.parking_carpooling) * 33.3 + 
				parseInt(this.label_input.box4.carpooling_incentives) * 33.3;

			this.label_result.box5 =
                parseInt(this.label_input.box5.carsharing || 0) * 13.0 +
                parseInt(this.label_input.box5.carsharing_convenzioni || 0) * 11.0 +
                parseInt(this.label_input.box5.bike_sharing || 0) * 11.0 +
                parseInt(this.label_input.box5.bike_sharing_convenzioni || 0) * 9.0 +
                (parseInt(this.label_input.box5.scooter_sharing) || 0) * 10.0 +
                (parseInt(this.label_input.box5.scooter_sharing_convenzioni) || 0)  * 8.0 +
                (parseInt(this.label_input.box5.monopattini) || 0) * 10.0 +
                (parseInt(this.label_input.box5.monopattini_convenzioni) || 0) * 8.0 +
                (parseInt(this.label_input.box5.incentives_sharing) || 0) * 20.0;

			this.label_result.box6 =
                parseInt(this.label_input.box6.accessibility) * 1.4 +
                parseInt(this.label_input.box6.business_trips) * 8.6 +
                parseInt(this.label_input.box6.efficient_use_work) * 5.6 +
                parseInt(this.label_input.box6.sensible_car_use) * 2.8 +
                parseInt(this.label_input.box6.park_ride) * 7.4 +
                parseInt(this.label_input.box6.parking_fee) * 5.9 +
                parseInt(this.label_input.box6.parking_management) * 14.4 +
                parseInt(this.label_input.box6.car_free) * 7.3 +
                parseInt(this.label_input.box6.access_management) * 7.3 +
                parseInt(this.label_input.box6.vhl_restrictions) * 7.3 +
                parseInt(this.label_input.box6.clean_vhls) * 2.9 +
                parseInt(this.label_input.box6.alternative_fuels) * 8.8 +
                parseInt(this.label_input.box6.eco_driving) * 8.8 +
                parseInt(this.label_input.box6.its) * 2.8 +
                parseInt(this.label_input.box6.route_planning) * 4.5 +
                parseInt(this.label_input.box6.traffic_info) * 4.2;

			this.label_result.box7 = 
				parseInt(this.label_input.box7.accessibility_signs) * 50 + 
				parseInt(this.label_input.box7.infrastructure_mobility) * 50;

			this.label_result.box8 =
                parseInt(this.label_input.box8.financial_incentives) * 20.0 +
                parseInt(this.label_input.box8.taxi_service) * 6.5 +
                parseInt(this.label_input.box8.trip_advice) * 9.0 +
                parseInt(this.label_input.box8.teleworking) * 11 +
                (parseInt(this.label_input.box8.flexibility) || 0) * 14.5 +
                parseInt(this.label_input.box8.mobility_office) * 16.0 +
                parseInt(this.label_input.box8.land_use) * 5.0 +
                parseInt(this.label_input.box8.e_shopping) * 7.5 +
                parseInt(this.label_input.box8.general_services) * 10.5;

			this.label_result.total =
                this.label_result.box1 +
                this.label_result.box2 +
                this.label_result.box3 +
                this.label_result.box4 +
                this.label_result.box5 +
                this.label_result.box6 +
                this.label_result.box7 +
                this.label_result.box8;

			//Calcolo il valore del label di ogni box

			if (this.label_result.box1 > 86.99) {
				this.label_result.class1 = "A++";
			} else if (this.label_result.box1 > 71.99 && this.label_result.box1 < 87) {
				this.label_result.class1 = "A+";
			} else if (this.label_result.box1 > 57.99 && this.label_result.box1 < 72) {
				this.label_result.class1 = "A";
			} else if (this.label_result.box1 > 43.99 && this.label_result.box1 < 58) {
				this.label_result.class1 = "B";
			} else if (this.label_result.box1 > 29.99 && this.label_result.box1 < 44) {
				this.label_result.class1 = "C";
			} else if (this.label_result.box1 > 14.99 && this.label_result.box1 < 30) {
				this.label_result.class1 = "D";
			} else {
				this.label_result.class1 = "G";
			}

			if (this.label_result.box2 > 86.99) this.label_result.class2 = "A++";
			else if (this.label_result.box2 > 71.99 && this.label_result.box2 < 87) this.label_result.class2 = "A+";
			else if (this.label_result.box2 > 57.99 && this.label_result.box2 < 72) this.label_result.class2 = "A";
			else if (this.label_result.box2 > 43.99 && this.label_result.box2 < 58) this.label_result.class2 = "B";
			else if (this.label_result.box2 > 29.99 && this.label_result.box2 < 44) this.label_result.class2 = "C";
			else if (this.label_result.box2 > 14.99 && this.label_result.box2 < 30) this.label_result.class2 = "D";
			else this.label_result.class2 = "G";

			if (this.label_result.box3 > 86.99) this.label_result.class3 = "A++";
			else if (this.label_result.box3 > 71.99 && this.label_result.box3 < 87) this.label_result.class3 = "A+";
			else if (this.label_result.box3 > 57.99 && this.label_result.box3 < 72) this.label_result.class3 = "A";
			else if (this.label_result.box3 > 43.99 && this.label_result.box3 < 58) this.label_result.class3 = "B";
			else if (this.label_result.box3 > 29.99 && this.label_result.box3 < 44) this.label_result.class3 = "C";
			else if (this.label_result.box3 > 14.99 && this.label_result.box3 < 30) this.label_result.class3 = "D";
			else this.label_result.class3 = "G";

			if (this.label_result.box4 > 86.99) this.label_result.class4 = "A++";
			else if (this.label_result.box4 > 71.99 && this.label_result.box4 < 87) this.label_result.class4 = "A+";
			else if (this.label_result.box4 > 57.99 && this.label_result.box4 < 72) this.label_result.class4 = "A";
			else if (this.label_result.box4 > 43.99 && this.label_result.box4 < 58) this.label_result.class4 = "B";
			else if (this.label_result.box4 > 29.99 && this.label_result.box4 < 44) this.label_result.class4 = "C";
			else if (this.label_result.box4 > 14.99 && this.label_result.box4 < 30) this.label_result.class4 = "D";
			else this.label_result.class4 = "G";

			if (this.label_result.box5 > 86.99) this.label_result.class5 = "A++";
			else if (this.label_result.box5 > 71.99 && this.label_result.box5 < 87) this.label_result.class5 = "A+";
			else if (this.label_result.box5 > 57.99 && this.label_result.box5 < 72) this.label_result.class5 = "A";
			else if (this.label_result.box5 > 43.99 && this.label_result.box5 < 58) this.label_result.class5 = "B";
			else if (this.label_result.box5 > 29.99 && this.label_result.box5 < 44) this.label_result.class5 = "C";
			else if (this.label_result.box5 > 14.99 && this.label_result.box5 < 30) this.label_result.class5 = "D";
			else this.label_result.class5 = "G";

			if (this.label_result.box6 > 86.99) this.label_result.class6 = "A++";
			else if (this.label_result.box6 > 71.99 && this.label_result.box6 < 87) this.label_result.class6 = "A+";
			else if (this.label_result.box6 > 57.99 && this.label_result.box6 < 72) this.label_result.class6 = "A";
			else if (this.label_result.box6 > 43.99 && this.label_result.box6 < 58) this.label_result.class6 = "B";
			else if (this.label_result.box6 > 29.99 && this.label_result.box6 < 44) this.label_result.class6 = "C";
			else if (this.label_result.box6 > 14.99 && this.label_result.box6 < 30) this.label_result.class6 = "D";
			else this.label_result.class6 = "G";

			if (this.label_result.box6 > 86.99) this.label_result.class6 = "A++";
			else if (this.label_result.box6 > 71.99 && this.label_result.box6 < 87) this.label_result.class6 = "A+";
			else if (this.label_result.box6 > 57.99 && this.label_result.box6 < 72) this.label_result.class6 = "A";
			else if (this.label_result.box6 > 43.99 && this.label_result.box6 < 58) this.label_result.class6 = "B";
			else if (this.label_result.box6 > 29.99 && this.label_result.box6 < 44) this.label_result.class6 = "C";
			else if (this.label_result.box6 > 14.99 && this.label_result.box6 < 30) this.label_result.class6 = "D";
			else this.label_result.class6 = "G";

			if (this.label_result.box7 > 86.99) this.label_result.class7 = "A++";
			else if (this.label_result.box7 > 71.99 && this.label_result.box7 < 87) this.label_result.class7 = "A+";
			else if (this.label_result.box7 > 57.99 && this.label_result.box7 < 72) this.label_result.class7 = "A";
			else if (this.label_result.box7 > 43.99 && this.label_result.box7 < 58) this.label_result.class7 = "B";
			else if (this.label_result.box7 > 29.99 && this.label_result.box7 < 44) this.label_result.class7 = "C";
			else if (this.label_result.box7 > 14.99 && this.label_result.box7 < 30) this.label_result.class7 = "D";
			else this.label_result.class7 = "G";

			if (this.label_result.box8 > 86.99) this.label_result.class8 = "A++";
			else if (this.label_result.box8 > 71.99 && this.label_result.box8 < 87) this.label_result.class8 = "A+";
			else if (this.label_result.box8 > 57.99 && this.label_result.box8 < 72) this.label_result.class8 = "A";
			else if (this.label_result.box8 > 43.99 && this.label_result.box8 < 58) this.label_result.class8 = "B";
			else if (this.label_result.box8 > 29.99 && this.label_result.box8 < 44) this.label_result.class8 = "C";
			else if (this.label_result.box8 > 14.99 && this.label_result.box8 < 30) this.label_result.class8 = "D";
			else this.label_result.class8 = "G";

			//Calcolo il valore totale della label di tutta l'azienda
			if (this.label_result.total > 707) {
				this.label_result.class_total = "A+++";
			} else if (this.label_result.total > 615.99 && this.label_result.total < 708) {
				this.label_result.class_total = "A++";
			} else if (this.label_result.total > 524.99 && this.label_result.total < 616) {
				this.label_result.class_total = "A++";
			} else if (this.label_result.total > 399.99 && this.label_result.total < 525) {
				this.label_result.class_total = "A";
			} else if (this.label_result.total > 299.99 && this.label_result.total < 400) {
				this.label_result.class_total = "B";
			} else if (this.label_result.total > 224.99 && this.label_result.total < 300) {
				this.label_result.class_total = "C";
			} else if (this.label_result.total > 149.99 && this.label_result.total < 225) {
				this.label_result.class_total = "D";
			} else if (this.label_result.total > 99.99 && this.label_result.total < 150) {
				this.label_result.class_total = "E";
			} else if (this.label_result.total > 49.99 && this.label_result.total < 100) {
				this.label_result.class_total = "F";
			} else {
				this.label_result.class_total = "G";
			}
		},
	},
	//at load page
	created: function () {
		this.curDate = this.getDate();
		this.office_id = this.$route.params.office_id;
		this.label_input = this.retriveOfficeLabelData(this.office_id);
	},
	mounted() {
		this.pb = this.gpb;
	},
};
</script>

<style scoped>
.boxed {
	border:1px solid silver; 
	padding: 1em;
	margin: 1em; 
	position: relative
}
#main-container {
	background-color: white;
}
#cell_r0_c0 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 21px;
  height: 114px;
}
#cell_r0_c1 {
  position: absolute;
  left: 21px;
  top: -1px;
  width: 478px;
  height: 21px;
}
#cell_r0_c2 {
  position: absolute;
  left: 499px;
  top: 0px;
  width: 23px;
  height: 114px;
}
#cell_r1_c0 {
  position: absolute;
  left: 21px;
  top: 21px;
  width: 140px;
  height: 93px;
}
#cell_r1_c1 {
  position: absolute;
  left: 161px;
  top: 21px;
  width: 4px;
  height: 93px;
}
#cell_r1_c2 {
  position: absolute;
  left: 165px;
  top: 21px;
  width: 334px;
  height: 93px;
}
#cell_r2_c0 {
  position: absolute;
  left: 0px;
  top: 114px;
  width: 21px;
  height: 91px;
}
#cell_r2_c1 {
  position: absolute;
  left: 21px;
  top: 114px;
  width: 478px;
  height: 91px;
}
#cell_r2_c2 {
  position: absolute;
  left: 499px;
  top: 114px;
  width: 22px;
  height: 91px;
}
#cell_r2_c3 {
  position: absolute;
  left: 521px;
  top: 114px;
  width: 1px;
  height: 91px;
}
#cell_r3_c0 {
  position: absolute;
  left: 0px;
  top: 205px;
  width: 21px;
  height: 456px;
}
#cell_r3_c1 {
  position: absolute;
  left: 21px;
  top: 205px;
  width: 333px;
  height: 456px;
}
#cell_r3_c2 {
  position: absolute;
  left: 354px;
  top: 205px;
  width: 145px;
  height: 456px;
}
#cell_r3_c3 {
  position: absolute;
  left: 499px;
  top: 205px;
  width: 23px;
  height: 456px;
}
#cell_r4_c0 {
  position: absolute;
  left: 0px;
  top: 661px;
  width: 21px;
  height: 265px;
}
#cell_r4_c1 {
  position: absolute;
  left: 21px;
  top: 661px;
  width: 478px;
  height: 265px;
}
#cell_r4_c2 {
  position: absolute;
  left: 499px;
  top: 661px;
  width: 23px;
  height: 265px;
}
#cell_r5_c0 {
  position: absolute;
  left: 0px;
  top: 926px;
  width: 21px;
  height: 166px;
}
#cell_r5_c1 {
  position: absolute;
  left: 21px;
  top: 926px;
  width: 478px;
  height: 153px;
}
#cell_r5_c2 {
  position: absolute;
  left: 499px;
  top: 926px;
  width: 23px;
  height: 166px;
}
#cell_r6_c0 {
  position: absolute;
  left: 21px;
  top: 1074px;
  width: 478px;
  height: 13px;
}

body {
  font-family: sans-serif;
}
.issue-date {
  text-align: right;
}
#cell_r2_c1 h1 {
  text-align: center;
  font-size: 30px;
}
#cell_r2_c1 {
  border-bottom: 2px solid lightgreen;
}
#main-container {
  position: relative;
  margin: 10px auto;
  width: 500px;
  height: 1100px;
}
#cell_r3_c2 {
  font-size: 35px;
  font-weight: bold;
  color: white;
  background-color: black;
  height: 1.1em;
  text-align: center;
}
#cell_r4_c1 img {
  padding: 5px;
}
#cell_r4_c1 div.icone {
  margin-left: 20px;
  border-top: 1px solid gray;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
}
#logo {
  text-align: center;
  width: 100%;
  padding-top: 1px;
}
#logo img {
  padding: 0;
  vertical-align: middle;
}
#cell_r5_c1 {
  font-size: 14px;
}
</style>
