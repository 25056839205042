<template>
    <div class="row">
        <div class="col-12">
            <card title="Spazi di Coworking" subTitle="Elenco degli Spazi disponibili per il Coworking">
                <div slot="raw-content" class="table-responsive">
                    <b-table :items="coworking" :fields="fields"> </b-table>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import UserService from "@/services/user.service";
const fields = ["name", "address", "cap", "city", "province"];
export default {
	components: {},
	data() {
		return {
			coworking: [],
			fields: fields,
		};
	},
	created() {
		UserService.getCoworking()
			.then((response) => {
				this.coworking = response.data.coworking;
			})
			.catch((error) => this.showError(error));
	},
};
</script>
<style></style>
