<template>
    <div>
        <h2>Riepilogo e Completamento dell'Acquisto</h2>
        <abbonamento-card :abbonamento_selezionato="form.abbonamento_selezionato" :prezzo_pieno="prezzo_pieno" :prezzo_scontato="prezzo_scontato"> </abbonamento-card>
        <b-row>
            <b-col>
                <b-form-checkbox
                    id="checkbox-privacy"
                    v-model="form.stato_privacy"
                    name="checkbox-privacy"
                    value="accettato"
                    unchecked-value="non_accettato"
                    class="my-4 float-right">
                    <strong>Autorizzo la trattenuta del costo dell’abbonamento dalla mia busta paga.</strong>
                </b-form-checkbox>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import AbbonamentoCard from "@/components/Abbonamento/AbbonamentoCard";

export default {
	name: "abbonamentoRiepilogo",
	props: {
		form: Object,
		prezzo_pieno: Number,
		prezzo_scontato: Number,
	},
	components: {
		AbbonamentoCard,
	},
	data() {
		return {
			loading: false,
		};
	},
};
</script>
<style></style>
