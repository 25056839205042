<template>
    <div class="page-holder bg-cover d-flex flex-column sticky-footer-wrapper cover-back">
        <b-container class="flex-fill">
            <b-card class="overflow-hidden mt-5" footer-tag="footer" :class="logo ? 'col-md-8 offset-md-2' : 'col-md-6 offset-md-3'">
                <b-overlay :show="loading" rounded="sm">
                    <div>
                        <h1>Single Sign On Jointly</h1>
                        <b-progress :value="value" :max="max" show-progress animated variant="success"></b-progress>
                        <h4>Privacy: <span class="small">Consenso al trattamento dei dati</span></h4>
                        <p>
                            I dati forniti alla piattaforma Mobility48 saranno gestiti da iMpronta solo per i servizi di mobilità attivati di volta in volta dall'utente (es:
                            Sportello Abbonamenti). <br />
                            L'utente prende atto che per lo svolgimento dell'attività, i dati raccolti dovranno essere comunicati ai seguenti soggetti:
                        </p>
                        <ul>
                            <li>Jointly</li>
                            <li>Operatori del Trasporto Pubblico Locale scelti dall'utente</li>
                            <li>Azienda a cui appartiene l'utente - Ufficio Paghe e Ufficio del Personale</li>
                        </ul>
                        <a href="https://impronta48.it/privacy" class="text-muted float-right small">Privacy Policy iMpronta</a>
                        <b-form-checkbox v-model="privacy" required name="privacy" id="privacy"> Ho preso atto e voglio procedere</b-form-checkbox>
                        <b-button variant="primary" class="mt-3" @click="checkPrivacy()" :disabled="nogo">Procedo verso il servizio</b-button>
                    </div>
                </b-overlay>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import authService from "@/services/auth.service";
import User from "@/models/user";
import UserService from "@/services/user.service";
import Util from "@/mixing/util.js";

export default {
	name: "AbbonamentiJointly",
	mixins: [Util],
	data() {
		return {
			value: 50,
			max: 100,
			privacy: false,
			nogo: false,
		};
	},
	computed: {},
	async created() {
		let data = this.$route.query.data;
		let user = new User("", "");
		this.value = 60;
		try {
			user = await authService.jointly(data, user);
			this.value = 70;
			/* if (user.tos_date == null) {
				this.privacy = false;
			} else {
				this.privacy = true;
			} */
			this.nogo = false;
			this.checkPrivacy();
		} catch (error) {
			this.nogo = true;
			this.$bvModal.msgBoxOk(`L'utente richiesto non è presente su questa piattaforma.
                       Verificare la modalità di accesso con il proprio referente.\n\r
                       ${error.response.data.message}
                       `);
		}
	},
	methods: {
		checkPrivacy() {
			if (this.privacy == true) {
				UserService.updateTos();
				this.value = 100;
				window.location = "/abbonamenti";
			} else {
				alert("Per procedere è necessario accettare la privacy policy ed i termini del servizio");
			}
		},
	},
};
</script>
